import styled from 'styled-components';

const SubCategoryStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  svg {
    margin-right: 5px;
  }

  a {
    text-wrap: auto;
  }
`;

export default SubCategoryStyled;
