import type { IConfig } from '../interfaces/IConfig';

const config: IConfig = {
  graphQLUri: 'https://gateway-dev.cvetgss.io/rxmgt/irx-api/graphql',
  contexts: 'https://gateway-dev.cvetgss.io/rxmgt/irx-api/contexts',
  exports: 'https://gateway-dev.cvetgss.io/rxmgt/irx-api/exports',

  auth0Domain: 'covetrus-int.auth0.com',
  auth0ClientId: 'A16KVGxsa0MjHAx4sxo8zxcgjPPxTtqi',
  imageUpload: 'https://gateway-dev.cvetgss.io/rxmgt/irx-api/platform-account',
  artifactUpload: 'https://gateway-dev.cvetgss.io/rxmgt/irx-api/artifacts',
  practice: 'https://gateway-dev.cvetgss.io/rxmgt/irx-api/practice',
  reportDownload:
    'https://gateway-dev.cvetgss.io/rxmgt/irx-api/reports/prescribing',
  defaultProductImage:
    'https://d181rm1bh0chxr.cloudfront.net/image/ac3fc591-a431-4cfb-8e4a-bcad09969a24.png',
  errors: 'https://gateway-dev.cvetgss.io/rxmgt/irx-api/errors',
};

export default config;
