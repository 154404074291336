import { Outlet, useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
// eslint-disable-next-line max-len
import PracticeSelectorContainerForMobileView from '@vrxComponents/PracticeSelector/PracticeSelectorContainerForMobileView';
import useGetDynamicFeatureFlags from '@vrxHooks/useGetDynamicFeatureFlags';
import LeftNavigation from '../../components/LeftNavigation/LeftNavigation';
import TopBar from '../../components/TopBar/TopBar';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import GenericError from '../../components/GenericError/GenericError';
import Toaster from '../../components/Toaster/Toaster';
import usePracticeSelection from '../../hooks/usePracticeSelection/usePracticeSelection';
import FullScreenLoader from '../../components/FullScreenLoader/FullScreenLoader';
import RootContextStyles from './styles/RootContextStyles';
import usePermissions from '../../hooks/usePermissions';
import useStorefrontClientUrl from '../../hooks/useStorefrontClientUrl';
import RootContextContainerStyled from './styles/RootContextContainer';
import type { RootContextProps } from './types/RootContextProps';

const AppContext = React.createContext<RootContextProps>(
  {} as RootContextProps
);

const RootContext = ({ loading }: { loading?: boolean }) => {
  const { loading: contextLoading } = usePracticeSelection();
  const { loading: permissionsLoading } = usePermissions();
  const { loading: storefrontLoading, storefrontUrl } =
    useStorefrontClientUrl();
  const location = useLocation();
  useGetDynamicFeatureFlags(!contextLoading);

  const renderFullScreenLoading =
    loading || contextLoading || permissionsLoading || storefrontLoading;

  const readyToRender = useMemo(
    () => ({ loading: renderFullScreenLoading }),
    [loading, contextLoading, permissionsLoading, storefrontLoading]
  );

  return readyToRender.loading ? (
    <FullScreenLoader />
  ) : (
    <AppContext value={readyToRender}>
      <>
        <RootContextStyles />
        <TopBar />
        <LeftNavigation storefrontUrl={storefrontUrl ?? ''} />
        <RootContextContainerStyled>
          <Toaster />
          <ErrorBoundary
            key={location.pathname}
            FallbackComponent={<GenericError />}
          >
            <PracticeSelectorContainerForMobileView />
            <Outlet />
          </ErrorBoundary>
        </RootContextContainerStyled>
      </>
    </AppContext>
  );
};

export { RootContext, AppContext };
