import styled from 'styled-components';

export const InternalLogoutStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  img {
    width: 170px;
    aspect-ratio: auto 170 / 41;
    height: 41px;
    margin: 4rem;
  }
`;

export const BodyContainerStyled = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img {
    height: 318px;
    width: 502px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SeparatorStyled = styled.div`
  border-left: 0.0625rem solid #e8e8e8;
  height: 25rem;
  @media only screen and (max-width: 768px) {
    width: 25rem;
    height: 5rem;
    margin-top: 4rem;
    border-left: 0;
    border-top: 0.0625rem solid #e8e8e8;
  }
`;

export const ColumnStyled = styled.div`
  flex-direction: column;
`;

export const LoginHeaderStyled = styled.div`
  font-family: 'Newzald-Bold', 'Georgia', sans-serif;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #021660;
  margin: 0 0 0.5rem 0;
`;

export const MessageStyled = styled.div`
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #191919;
  margin: 0 0 1.5rem 0;
`;
