import { Locales } from '@covetrus/reference-data';
import type { MessageFormatElement } from 'react-intl';

export type LocaleData = Record<string, Array<MessageFormatElement>>
function loadLocaleData(
  locale: Locales
): Promise<{
  default: LocaleData;
}> {
  // eslint-disable-next-line no-console
  console.log(locale);

  switch (locale) {
    case Locales.en_US:  return import('./compiled-lang/en-US.json');
    case Locales.es_ES:
    case Locales.fr_FR:
      console.warn('Returning en-US instead of en-AU because no translations are available.');
      return import('./compiled-lang/en-US.json');
    // case Locale['fr-FR']:
    //   console.warn('French translations are for demo purposes only.');
    //   return import('./compiled-lang/fr-FR.json');
    default:
      return import('./compiled-lang/en-US.json');
  }
}

export default loadLocaleData;
