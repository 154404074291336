/* eslint-disable no-param-reassign */
import type { IntlShape } from 'react-intl';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import type { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { ToastType } from '@covetrus/design-system-library';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SearchReportsByAcbQuery } from '../../api/generated/graphql';
import { addToast } from '../../components/Toaster/toaster.actions';
import config from '../../config/config';
import downloadFile from '../../utils/downloadFile';
import type { RootState } from '../store/Store';
import {
  APPLICATION_JSON,
  APPLICATION_ZIP,
  OCTET_STREAM,
  ZIP_EXTENSION,
  getBoundary,
  getMultiparts,
} from '../../utils/getMultiparts';

export interface ReportState {
  selection: Record<
    string,
    SearchReportsByAcbQuery['searchReportsByAcb']['reports'][0]
  >;
  downloadLoading: boolean;
  totalResults: number | null;
}

export interface DownloadSingleReportInput {
  report: SearchReportsByAcbQuery['searchReportsByAcb']['reports'][0];
  instance: SearchReportsByAcbQuery['searchReportsByAcb']['reports'][0]['instances'][0];
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string>;
  contextKey?: string;
}

export interface DownloadMultipleReportsInput {
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string>;
  intl: IntlShape;
  contextKey?: string;
}

export interface ReportIds {
  ids: Array<string>;
}

const initialState: ReportState = {
  selection: {},
  downloadLoading: false,
  totalResults: null,
};

export const downloadSingleReport = createAsyncThunk(
  'reports/downloadSingleReport',
  async ({
    report,
    instance,
    getAccessTokenSilently,
    contextKey,
  }: DownloadSingleReportInput) => {
    try {
      const token = await getAccessTokenSilently();
      if (!token) throw new Error('Not Authenticated');

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      if (contextKey) {
        axios.defaults.headers.common.contextKey = contextKey;
      }

      const promises: Array<Promise<AxiosResponse>> = [];
      const uniqueInstance: Record<string, boolean> = {};
      const sortByModifedDate = [...report.instances];
      sortByModifedDate.sort((a, b) => {
        return (
          new Date(b.modifiedDate).getTime() -
          new Date(a.modifiedDate).getTime()
        );
      });
      sortByModifedDate.filter((reportInstance) => {
        if (!reportInstance.isActive) return false;

        if (!uniqueInstance[reportInstance.fileTypeCode]) {
          uniqueInstance[reportInstance.fileTypeCode] = true;
          return true;
        }
        return false;
      });

      if (instance) {
        // Download a single instance
        const response = await axios.get(
          `${config[process.env.REACT_APP_ENV || 'local']?.reportDownload}/${
            report.animalCareBusinessKey
          }/${instance.instanceKey}`,
          { responseType: 'blob' }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const fileName = response.headers.filename;
        const { extension } = response.headers;
        if (fileName && extension) {
          downloadFile(`${fileName}.${extension}`, url);
        }
      } else {
        // Download all instances
        report.instances.forEach((reportInstance) => {
          if (reportInstance.isActive) {
            const promise = axios.get(
              `${
                config[process.env.REACT_APP_ENV || 'local']?.reportDownload
              }/${report.animalCareBusinessKey}/${reportInstance.instanceKey}`,
              { responseType: 'blob' }
            );
            promises.push(promise);
          }
        });
        const responses = await Promise.all(promises);
        responses.forEach(({ data, headers }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const fileName = headers.filename;
          const { extension } = headers;
          if (fileName && extension) {
            downloadFile(`${fileName}.${extension}`, url);
          }
        });
      }
    } catch (e: unknown) {
      console.error(e);
      throw e;
    }
  }
);

export const downloadMultipleReports = createAsyncThunk<
  Promise<void>,
  DownloadMultipleReportsInput,
  { state: RootState }
>(
  'reports/downloadMultipleReports',
  async (
    { intl, getAccessTokenSilently, contextKey }: DownloadMultipleReportsInput,
    { getState }
  ) => {
    try {
      const token = await getAccessTokenSilently();
      if (!token) throw new Error('Not Authenticated');

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      if (contextKey) {
        axios.defaults.headers.common.contextKey = contextKey;
      }

      const reports = Object.values(getState().Report.selection);
      const reportInstanceKeys: Array<string> = [];

      reports.forEach(({ instances }) => {
        instances.forEach(({ instanceKey }) => {
          reportInstanceKeys.push(instanceKey);
        });
      });

      if (!reports.length) {
        return;
      }

      const acbKey = reports[0].animalCareBusinessKey;

      const response = await axios.post<Blob>(
        `${
          config[process.env.REACT_APP_ENV || 'local']?.reportDownload
        }/${acbKey}/download-files`,
        { reportInstanceKeys },
        { responseType: 'blob' }
      );

      const buffer = Buffer.from(await response.data.arrayBuffer());

      if (!response.headers['content-type']) {
        throw new Error('Content-Type Header is missing');
      }

      const boundary = getBoundary(response.headers['content-type'].toString());
      const parts = getMultiparts(buffer, boundary);

      const zipPart = parts.find((part) => part.type === OCTET_STREAM);
      const errorsPart = parts.find((part) => part.type === APPLICATION_JSON);

      const errors = errorsPart?.data
        ? JSON.parse(errorsPart?.data.toString())?.errorReportInstanceKeys
        : [];

      if (errors.length) {
        addToast({
          type: ToastType.DANGER,
          content: intl.formatMessage({
            defaultMessage: 'Some reports failed to download',
          }),
        });
        return;
      }

      if (!zipPart) {
        throw new Error('No zip file was included');
      }

      const url = window.URL.createObjectURL(
        new Blob([zipPart?.data], { type: APPLICATION_ZIP })
      );

      const fileName = zipPart.filename ?? 'reports';

      if (fileName) {
        downloadFile(
          `${fileName}${fileName.indexOf(ZIP_EXTENSION) ? '' : ZIP_EXTENSION}`,
          url
        );
      }
    } catch (e: unknown) {
      console.error(e);
      addToast({
        type: ToastType.DANGER,
        content: intl.formatMessage({
          defaultMessage: 'Something went wrong',
        }),
      });
      return;
    }
    addToast({
      type: ToastType.SUCCESS,
      content: intl.formatMessage({
        defaultMessage: 'Download Successful',
      }),
    });
  }
);

export const ReportSlice = createSlice({
  initialState,
  name: 'report',
  reducers: {
    addSelection(
      state,
      action: PayloadAction<
        SearchReportsByAcbQuery['searchReportsByAcb']['reports']
      >
    ) {
      action.payload.forEach((report) => {
        state.selection[report.id] = report;
      });
    },
    setTotalResults(state, action: PayloadAction<number>) {
      state.totalResults = action.payload;
    },
    resetSelection(state) {
      state.selection = {};
    },
    removeSelection(state, action: PayloadAction<ReportIds>) {
      action.payload.ids.forEach((id) => {
        delete state.selection[id];
      });
    },
    toggleSelection(
      state,
      action: PayloadAction<
        SearchReportsByAcbQuery['searchReportsByAcb']['reports']
      >
    ) {
      action.payload.forEach((report) => {
        if (state.selection[report.id]) {
          delete state.selection[report.id];
        } else {
          state.selection[report.id] = report;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadSingleReport.pending, (state) => {
        state.downloadLoading = true;
      })
      .addCase(downloadSingleReport.fulfilled, (state) => {
        state.downloadLoading = false;
      })
      .addCase(downloadSingleReport.rejected, (state) => {
        state.downloadLoading = false;
      })
      .addCase(downloadMultipleReports.pending, (state) => {
        state.downloadLoading = true;
      })
      .addCase(downloadMultipleReports.fulfilled, (state) => {
        state.downloadLoading = false;
      })
      .addCase(downloadMultipleReports.rejected, (state) => {
        state.downloadLoading = false;
      });
  },
});

export const { reducer: Report, actions: ReportActions } = ReportSlice;
