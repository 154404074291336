import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { PartnerApiSearchStatus } from '../slices/PartnerApiSearchStatus.slice';
import { FeatureFlags } from '../slices/FeatureFlags.slice';
import { PatientProfile } from '../slices/PatientProfile.slice';
import { UserProfile } from '../slices/UserProfile.slice';
import { LeftNav } from '../slices/LeftNav.slice';
import { ManageShortcuts } from '../slices/ManageShortcuts.slice';
import { PracticeSearchStatus } from '../slices/PracticeSearchStatus.slice';
import { ClientRequests } from '../slices/ClientRequests.slice';
import { Renewals } from '../slices/Renewals.slice';
import { Language } from '../slices/Language.slice';
import { Report } from '../slices/Reports.slice';
import { BrandPreferences } from '../slices/BrandPreferences.slice';
import { UserStatus } from '../slices/UserStatus.slice';

const Store = configureStore({
  reducer: {
    PatientProfile,
    UserProfile,
    LeftNav,
    UserStatus,
    ManageShortcuts,
    PracticeSearchStatus,
    PartnerApiSearchStatus,
    ClientRequests,
    Renewals,
    Language,
    Report,
    BrandPreferences,
    FeatureFlags,
  },
  devTools: process.env.REACT_APP_ENV === 'local',
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default Store;
