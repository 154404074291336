import type { GraphQLErrors } from '@apollo/client/errors';
import type { ErrorResponse } from '@apollo/client/link/error';
import * as React from 'react';
import GenericError from '../GenericError/GenericError';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import NotFound from '../NotFound/NotFound';

interface State {
  hasError: boolean;
  httpResponseStatus?: number;
  error?: Error | GraphQLErrors;
}

export interface ErrorLog {
  error?: string;
  stack?: string;
  cause?: string;
}

interface FallbackComponentRequiredProps {
  errorBoundaryError: ErrorLog;
}

interface Props {
  FallbackComponent: React.ReactElement<FallbackComponentRequiredProps>;
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line
  static getDerivedStateFromError(e: ErrorResponse | any) {
    // You can also log the error to an error reporting service
    if (e.graphQLErrors) {
      const gqlErrors: GraphQLErrors = e.graphQLErrors;
      if (
        gqlErrors.length &&
        (gqlErrors[0].extensions?.exception as { status: number })?.status
      ) {
        return {
          hasError: true,
          httpResponseStatus: (
            gqlErrors[0].extensions?.exception as { status: number }
          )?.status,
        };
      }
    }
    return { hasError: true, ...(e instanceof Error && { error: e }) };
  }

  render() {
    const { hasError, httpResponseStatus, error } = this.state;
    const { children, FallbackComponent } = this.props;

    if (error && error instanceof Error) {
      if (error.message === 'Failed to fetch user contexts')
        return <NotAuthorized />;
    }

    if (hasError) {
      switch (httpResponseStatus) {
        case 403:
        case 401:
          return <NotAuthorized />;
        case 404:
          return <NotFound />;
        case 500:
          return <GenericError />;
        default: {
          if (error && 'graphqlErrors' in error) {
            return FallbackComponent;
          }
          const errorLog: ErrorLog = {};

          if (typeof error === 'string') {
            errorLog.error = error;
          } else if (error instanceof Error) {
            errorLog.error = error.message;
            if (typeof error.cause === 'string') {
              errorLog.cause = error.cause;
            }
            errorLog.stack = error.stack;
          }

          if (errorLog.error) {
            return React.cloneElement(FallbackComponent, {
              errorBoundaryError: errorLog,
            });
          }

          return FallbackComponent;
        }
      }
    }

    return children;
  }
}

export default ErrorBoundary;
