import type { ApolloError } from '@apollo/client';
import { Permission } from '@covetrus/reference-data';
import { useGetMeQuery } from '../api/generated/graphql';

interface Access {
  // query status
  loading?: boolean;
  error?: ApolloError;

  // permissions
  userManagement: boolean;
  readPracticeUser: boolean;
  readDrafts: boolean;
  manageDrafts: boolean;
  readPractice: boolean;
  readProducts: boolean;
  readPrescription: boolean;
  readClient: boolean;
  manageClient: boolean;
  manageStorefront: boolean;
  readStorefront: boolean;
  readReportsKPI: boolean;
  readReportsFinancial: boolean;
  readReportsAggregate: boolean;
  readOrderHistory: boolean;
  managePrescriptionPharmTech: boolean;
  managePrescriptionDelegate: boolean;
  managePrescription: boolean;
  modifyPrescription: boolean;
  readBrandPreferences: boolean;
  manageBrandPreferences: boolean;
  delegateApproval: boolean;
  readPracticePricing: boolean;
  manageDelegateSelf: boolean;
  manageSupportServices: boolean;
  listPermissions: Array<string>;
  managePracticeProductCatalogPricing: boolean;
  managePatients: boolean;
  // utility
  hasPermission: (perm: Permission) => boolean;
  hasAnyPermissionOnList: (permList: Array<Permission>) => boolean;
}

const usePermissions = (skip?: boolean): Access => {
  const { data, error, loading } = useGetMeQuery({ skip });

  const hasPermission = (perm: Permission): boolean => {
    return data?.getMe.permissions.some((role) => role === perm) ?? false;
  };

  const hasAnyPermissionOnList = (permList: Array<Permission>): boolean => {
    return (
      data?.getMe.permissions.some((permission) => {
        let allowed = false;
        permList.forEach((allowedPermission) => {
          if (permission === allowedPermission) allowed = true;
        });
        return allowed;
      }) ?? false
    );
  };

  if (loading || error || !data) {
    return {
      userManagement: false,
      readPracticeUser: false,
      readDrafts: false,
      manageDrafts: false,
      readPractice: false,
      readProducts: false,
      readPrescription: false,
      readClient: false,
      manageClient: false,
      manageStorefront: false,
      readStorefront: false,
      readReportsKPI: false,
      readReportsFinancial: false,
      readReportsAggregate: false,
      readOrderHistory: false,
      readPracticePricing: false,
      loading: loading || skip,
      managePrescriptionPharmTech: false,
      managePrescriptionDelegate: false,
      managePrescription: false,
      modifyPrescription: false,
      readBrandPreferences: false,
      manageBrandPreferences: false,
      delegateApproval: false,
      manageDelegateSelf: false,
      manageSupportServices: false,
      managePracticeProductCatalogPricing: false,
      managePatients: false,
      error,
      listPermissions: [],
      hasPermission,
      hasAnyPermissionOnList,
    };
  }

  const listPermissions = (() => {
    if (data?.getMe) {
      return data.getMe.permissions;
    }
    return [];
  })();

  const userManagement = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_PRACTICE_USER
      );
    }
    return false;
  })();

  const readPracticeUser = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_PRACTICE_USER
      );
    }
    return false;
  })();

  const readDrafts = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_DRAFT_PRESCRIPTION
      );
    }
    return false;
  })();

  const manageDrafts = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_DRAFT_PRESCRIPTION
      );
    }
    return false;
  })();

  const readPractice = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_PRACTICE
      );
    }
    return false;
  })();

  const readPracticePricing = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_PRACTICE_PRICING
      );
    }
    return false;
  })();

  const readProducts = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_PRODUCTS
      );
    }
    return false;
  })();

  const readPrescription = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_PRESCRIPTION
      );
    }
    return false;
  })();

  const readClient = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_CLIENTS
      );
    }
    return false;
  })();

  const manageClient = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_CLIENTS
      );
    }
    return false;
  })();

  const manageStorefront = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_STOREFRONT
      );
    }
    return false;
  })();

  const readStorefront = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_STOREFRONT
      );
    }
    return false;
  })();

  const readReportsKPI = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_REPORTS_KPI
      );
    }
    return false;
  })();

  const readReportsFinancial = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_REPORTS_FINANCIAL
      );
    }
    return false;
  })();

  const readReportsAggregate = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_REPORTS_AGGREGATE
      );
    }
    return false;
  })();

  const readOrderHistory = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_ORDER_DETAILS
      );
    }
    return false;
  })();

  const managePrescriptionPharmTech = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_PRESCRIPTION_PHARM_TECH
      );
    }
    return false;
  })();

  const managePrescriptionDelegate = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_PRESCRIPTION_DELEGATE
      );
    }
    return false;
  })();

  const managePrescription = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_PRESCRIPTION
      );
    }
    return false;
  })();

  const modifyPrescription = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MODIFY_PRESCRIPTION
      );
    }
    return false;
  })();

  const readBrandPreferences = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.READ_BRAND_PREFERENCES
      );
    }
    return false;
  })();

  const manageBrandPreferences = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_BRAND_PREFERENCES
      );
    }
    return false;
  })();

  const delegateApproval = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_DELEGATE_APPROVAL
      );
    }
    return false;
  })();

  const manageDelegateSelf = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_DELEGATE_SELF
      );
    }
    return false;
  })();

  const managePatients = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_PATIENTS
      );
    }
    return false;
  })();

  const manageSupportServices = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_SUPPORT_SERVICES
      );
    }
    return false;
  })();

  const managePracticeProductCatalogPricing = (() => {
    if (data?.getMe) {
      return data.getMe.permissions.some(
        (role) => role === Permission.MANAGE_PRACTICE_PRODUCT_CATALOG_PRICING
      );
    }
    return false;
  })();

  return {
    userManagement,
    readPracticeUser,
    readDrafts,
    manageDrafts,
    readPractice,
    readPrescription,
    readProducts,
    readClient,
    manageClient,
    manageStorefront,
    readStorefront,
    readReportsKPI,
    readReportsAggregate,
    readReportsFinancial,
    readOrderHistory,
    readPracticePricing,
    managePrescriptionPharmTech,
    managePrescriptionDelegate,
    managePrescription,
    modifyPrescription,
    readBrandPreferences,
    manageBrandPreferences,
    delegateApproval,
    manageDelegateSelf,
    manageSupportServices,
    listPermissions,
    managePracticeProductCatalogPricing,
    managePatients,
    hasPermission,
    hasAnyPermissionOnList,
  };
};

export default usePermissions;
