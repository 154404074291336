import type { IConfig } from '../interfaces/IConfig';

const config: IConfig = {
  graphQLUri: 'https://api.covetrus.com/rxmgt/irx-api/graphql',
  contexts: 'https://api.covetrus.com/rxmgt/irx-api/contexts',
  exports: 'https://api.covetrus.com/rxmgt/irx-api/exports',

  auth0Domain: 'auth.covetrus.com',
  auth0ClientId: 'ECEsgE5ZTwvmbSqw1mkM4dVKRjepfqcS',
  imageUpload: 'https://api.covetrus.com/rxmgt/irx-api/platform-account',
  artifactUpload: 'https://api.covetrus.com/rxmgt/irx-api/artifacts',
  reportDownload: 'https://api.covetrus.com/rxmgt/irx-api/reports/prescribing',
  practice: 'https://api.covetrus.com/rxmgt/irx-api/practice',
  defaultProductImage:
    'https://d2gdm6lfmduyqo.cloudfront.net/image/d8822ebf-9fd8-454a-84de-7acb7df951dc.jpg',
  errors: 'https://api.covetrus.com/rxmgt/irx-api/errors',
};

export default config;
