import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import type { ReactElement } from 'react';
import { theme, useMediaQuery } from '@covetrus/design-system-library';
import type { RootState } from '../redux/store/Store';
import NotFound from '../components/NotFound/NotFound';
import NotAuthorized from '../components/NotAuthorized/NotAuthorized';
import BottomBar from '../components/BottomBar/BottomBar';
import {
  internalLoginPath,
  internalLogoutPath,
  isInternal,
  True,
} from '../constants/Constants';
import {
  InternalUserStatus,
  updateInternalLoginStatus,
  internalStatusReactiveVar,
} from '../State/InternalUserAuthStatus.state';
import { ConnectionOptions, updateConnection } from '../State/AuthConnection';
import InternalLogout from '../components/InternalLogout/InternalLogout';
import internalUserPaths from './internalUserPaths';
import corporateUserPaths from './corporateUserPaths';
import externalUserPaths from './externalUserPaths';
import lazyWithRefresh from '../utils/lazyWithRefresh';
import usePracticeSelection from '../hooks/usePracticeSelection/usePracticeSelection';
import { useNavigateNoUpdates } from '../contexts/NavigatorContextProvider';
import usePendo from '../hooks/usePendo';
import { RootContext } from '../domains/RootContext/RootContext';

const genericErrorImport = () =>
  import('../components/GenericError/GenericError');
interface RoutesStyledProps {
  isOpen: boolean;
  isMobile: boolean;
}

const RoutesStyled = styled.div<RoutesStyledProps>`
  display: flex;
  height: 100%;
  min-height: 100vh;
  padding-top: 64px;
  padding-left: ${({ isOpen, isMobile }) =>
    isMobile ? 0 : (isOpen && '245px') || '60px'};
  transition: padding 1s;
  margin-bottom: 50px;
`;

const GenericError = lazyWithRefresh(genericErrorImport);

const App = (): ReactElement => {
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();
  const leftNavOpen = useSelector((state: RootState) => state.LeftNav.isOpen);
  const isMobile = useMediaQuery(
    `(max-width: ${theme.displaySizes.smartphones})`
  );
  const isInternalUser = useSelector(
    (state: RootState) => state.UserStatus.isInternal
  );
  const isCorporateUser = useSelector(
    (state: RootState) => state.UserStatus.isCorporate
  );

  const { loading: contextLoading } = usePracticeSelection();
  const status = useReactiveVar(internalStatusReactiveVar);
  const navigate = useNavigateNoUpdates();
  usePendo();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isInternalQueryParam = queryParams.get(isInternal);
    if (!isLoading && (!user || !isAuthenticated)) {
      if (
        window.location.pathname === internalLoginPath ||
        isInternalQueryParam === True
      ) {
        updateInternalLoginStatus(InternalUserStatus.LOGGED_IN);
        updateConnection(ConnectionOptions.OKTA_USER);
        loginWithRedirect({
          authorizationParams: { connection: ConnectionOptions.OKTA_USER },
          appState: {
            returnTo: `${window.location.pathname}${window.location.search}`,
          },
        });
      } else if (window.location.pathname !== internalLogoutPath) {
        updateConnection(ConnectionOptions.COVETRUS_USER);
        loginWithRedirect({
          authorizationParams: { connection: ConnectionOptions.COVETRUS_USER },
          appState: {
            returnTo: `${window.location.pathname}${window.location.search}`,
          },
        });
      }
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (
      status === InternalUserStatus.LOGGED_IN &&
      isAuthenticated &&
      user &&
      window.location.pathname === internalLoginPath
    ) {
      navigate('/');
    }
  }, [status, isAuthenticated, user, window.location.pathname]);

  if (
    (status === InternalUserStatus.LOGGED_OUT &&
      !isLoading &&
      user &&
      isInternalUser &&
      !isAuthenticated) ||
    window.location.pathname === internalLogoutPath
  ) {
    return <InternalLogout />;
  }

  // Full Screen Loader is handled in RootContext
  if (contextLoading) return <></>;

  return (
    <>
      <RoutesStyled isOpen={leftNavOpen} isMobile={isMobile}>
        <React.Suspense fallback={<RootContext loading />}>
          <Routes>
            <Route path='' element={<RootContext />}>
              {/** If internal, show internal routes */}
              {isInternalUser && internalUserPaths()}
              {/** If corporate, show corporate routes, else show external */}
              {!isInternalUser &&
                (isCorporateUser ? corporateUserPaths() : externalUserPaths())}

              {/* Utility Routes */}
              <Route path='/error' element={<GenericError />} />
              <Route path='/not-authorized' element={<NotAuthorized />} />
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        </React.Suspense>
      </RoutesStyled>
      <BottomBar />
    </>
  );
};
export default App;
