import styled from 'styled-components';

const PracticeSearchStyled = styled.div`
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export default PracticeSearchStyled;
