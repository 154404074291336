import { lazy } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DynamicImportType = () => Promise<{ default: React.ComponentType<any> }>;

const lazyWithRefresh = (
  componentImportFn: DynamicImportType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.LazyExoticComponent<React.ComponentType<any>> => {
  return lazy(async () => {
    let ret;
    try {
      ret = await componentImportFn();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { name } = e as any;
      if (name && name === 'ChunkLoadError') {
        // eslint-disable-next-line
        console.log('ChunkLoadError found. refreshing to get latest changes.');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ret = window.location.reload() as any;
      } else {
        throw e;
      }
    }
    return ret;
  });
};

export default lazyWithRefresh;
