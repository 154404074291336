import local from './environments/local';
import dev from './environments/dev';
import qa from './environments/qa';
import stage from './environments/stage';
import prod from './environments/prod';
import type { IConfig } from './interfaces/IConfig';

const config: Record<string, IConfig> = { local, dev, qa, stage, prod };

export default config;
