import { theme } from '@covetrus/design-system-library';
import styled from 'styled-components';

export const ProfileSelectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px 4px 8px;
  margin-top: 16px;
  border-top: 1px solid ${theme.color.neutral200};
  max-height: 400px;
  overflow-y: auto;
`;

export const ProfileItemsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
`;

export const ProfileSubHeader = styled.div`
  font-size: ${theme.fontSize.sizeFontS};
  color: ${theme.color.neutral700};
`;
