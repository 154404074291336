import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../config/config';
import { authLink, errorLink } from './apolloLinks';

let apolloClient: ApolloClient<InMemoryCache>;

// eslint-disable-next-line
const initializeApolloClient = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const link = from([
    authLink(getAccessTokenSilently, loginWithRedirect),
    errorLink(),
    new HttpLink({
      uri: config[process.env.REACT_APP_ENV || 'local'].graphQLUri,
    }),
  ]);

  const cache = new InMemoryCache();

  return new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.REACT_APP_ENV === 'local',
  });
};
// eslint-disable-next-line
const getApolloClientInstance = () => {
  const _apolloClient = apolloClient ?? initializeApolloClient();
  if (!apolloClient) apolloClient = _apolloClient;

  return apolloClient;
};

export default getApolloClientInstance;
