import React from 'react';
import { type SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import type { OptionProps } from '@covetrus/design-system-library';
import { Dropdown } from '@covetrus/design-system-library';
import usePracticeSelection from '../../hooks/usePracticeSelection/usePracticeSelection';
import type PracticeSelectorProps from './types/PracticeSelectorProps';

const PracticeSelector = ({
  isPracticeUser = false,
}: PracticeSelectorProps) => {
  const { currentPractice, practiceOptions, selectPractice } =
    usePracticeSelection();
  const navigate = useNavigate();
  const intl = useIntl();

  const handlePracticeChange = (
    newPractice: OptionProps | SyntheticEvent<HTMLDivElement>
  ) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    selectPractice((newPractice as OptionProps).value);
    navigate('/');
  };

  if (practiceOptions.length <= 1) {
    return null;
  }

  return (
    <div>
      <Dropdown
        dropdownStyleOverrides={{ position: 'absolute' }}
        value={currentPractice}
        label={
          isPracticeUser
            ? intl.formatMessage({
                defaultMessage: 'Practice Selection',
              })
            : intl.formatMessage({
                defaultMessage: 'Location Selection',
              })
        }
        options={practiceOptions}
        onSelection={handlePracticeChange}
        pushOtherContent={false}
        useTypeahead
      />
    </div>
  );
};

export default PracticeSelector;
