import React from 'react';
import { createSearchParams, Link, useLocation } from 'react-router-dom';
import CountStyled from './CountStyled';
import type { MenuItemWithCounterProps } from '../types/MenuItemWithCounterProps';
import MenuItemWithCounterStyled from '../styles/MenuItemWithCounterStyled';

const MenuItemWithCounter: React.FC<MenuItemWithCounterProps> = ({
  count,
  label,
  loading,
  url,
  hide,
  practiceNameState,
}) => {
  const location = useLocation();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <Link
        to={{
          pathname: url,
          search: createSearchParams({
            sourceRoute: location.pathname,
          }).toString(),
        }}
        state={practiceNameState}
      >
        <MenuItemWithCounterStyled>{label}</MenuItemWithCounterStyled>
      </Link>
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
        {count && !loading && !hide ? (
          <CountStyled>{count > 99 ? '99+' : count}</CountStyled>
        ) : null}
      </div>
    </div>
  );
};

export default MenuItemWithCounter;
