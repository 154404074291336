export type Observer<T> = (data: T) => void;

/** Rather than importing the RxJS library. I have created a simple Subject */
class Subject<T> {
  private observers: Array<Observer<T>> = [];

  public value?: T;

  public observerCount: number;

  constructor() {
    this.observerCount = this.observers.length;
  }

  subscribe(observer: Observer<T>) {
    this.observers.push(observer);
    this.observerCount = this.observers.length;
  }

  unsubscribe(observer: Observer<T>) {
    this.observers = this.observers.filter((ob) => ob !== observer);
    this.observerCount = this.observers.length;
  }

  next(data: T) {
    this.value = data;
    this.observers.forEach((observer) => observer(data));
  }
}

export default Subject;
