/* eslint-disable max-len */
import React from 'react';
import { useIntl } from 'react-intl';
import {
  BottomBarStyled,
  BottomBarListsStyled,
} from './styles/BottomBarStyled';

const BottomBar = () => {
  const intl = useIntl();
  return (
    <BottomBarStyled className='bottom-bar-cp'>
      <div
        style={{
          margin: 'auto',
          width: '80%',
          textAlign: 'center',
          display: 'flex',
        }}
      >
        <BottomBarListsStyled>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://covetrus.com/legal/terms-of-service/'
              target='_blank'
              rel='noopener noreferrer'
            >
              {intl.formatMessage({ defaultMessage: 'Terms of Service' })}
            </a>
          </div>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://covetrus.com/legal/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              {intl.formatMessage({ defaultMessage: 'Privacy Policy' })}
            </a>
          </div>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://covetrus.com/legal/ryan-haight/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Ryan Haight
            </a>
          </div>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://www.fda.gov/drugs/safe-disposal-medicines/disposal-unused-medicines-what-you-should-know'
              target='_blank'
              rel='noopener noreferrer'
            >
              {intl.formatMessage({ defaultMessage: 'Medication Disposal' })}
            </a>
          </div>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://software.covetrus.com/veterinary-solutions/veterinary-prescription-management'
              target='_blank'
              rel='noopener noreferrer'
            >
              {intl.formatMessage({ defaultMessage: 'About US' })}
            </a>
          </div>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://covetrus.com/news-room'
              target='_blank'
              rel='noopener noreferrer'
            >
              {intl.formatMessage({ defaultMessage: 'Latest News' })}
            </a>
          </div>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://covetrus.wd5.myworkdayjobs.com/en-US/CovetrusCareers'
              target='_blank'
              rel='noopener noreferrer'
            >
              {intl.formatMessage({ defaultMessage: 'Careers' })}
            </a>
          </div>
          <div>
            <a
              style={{ color: '#fff' }}
              href='https://software.covetrus.com/support/prescription-services-support'
              target='_blank'
              rel='noopener noreferrer'
            >
              {intl.formatMessage({ defaultMessage: 'Contact Us' })}
            </a>
          </div>
        </BottomBarListsStyled>
      </div>
    </BottomBarStyled>
  );
};

export default BottomBar;
