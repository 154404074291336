import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import type { FunctionComponent } from 'react';
import getApolloClientInstance from './ApolloClientInstance';
import type PropsWithChildren from '../types/PropsWithChildren';

const CustomApolloProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const apolloClient = getApolloClientInstance();

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default CustomApolloProvider;
