import React from 'react';
import { Navigate } from 'react-router-dom';
import { Permission } from '@covetrus/reference-data';
import { useGetMeQuery } from '../../api/generated/graphql';
import type ProtectedRouteProps from './types/ProtectedRouteProps';

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedPermissions,
  noPermissionPath,
  requireManageSupportForProd,
  children,
}) => {
  const { data, error } = useGetMeQuery({
    fetchPolicy: 'cache-only',
  });

  const defaultNoPermissionPath = '/not-authorized';

  if (!data) throw new Error('User Data Missing');
  if (error) throw error;

  const hasManageSupportServicesPermission = data.getMe.permissions.find(
    (p) => p === Permission.MANAGE_SUPPORT_SERVICES
  );

  const isProdEnv = process.env.REACT_APP_ENV === 'prod';

  const hasPermission =
    data.getMe.permissions.some((permission) => {
      let allowed = false;
      allowedPermissions.forEach((allowedPermission) => {
        if (permission === allowedPermission) allowed = true;
      });
      return allowed;
    }) &&
    (!isProdEnv ||
      !requireManageSupportForProd ||
      hasManageSupportServicesPermission);

  if (!hasPermission) {
    return <Navigate to={noPermissionPath ?? defaultNoPermissionPath} />;
  }
  return <div>{children}</div>;
};

export default ProtectedRoute;
