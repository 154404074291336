/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ClientRequestsState {
  familyCodeLoading: boolean;
}

const initialState: ClientRequestsState = {
  familyCodeLoading: false,
};

export const ClientRequestsSlice = createSlice({
  initialState,
  name: 'clientRequests',
  reducers: {
    setFamilyCodeLoading(state, action: PayloadAction<boolean>) {
      state.familyCodeLoading = action.payload;
    },
  },
});

export const { reducer: ClientRequests, actions: ClientRequestsActions } =
  ClientRequestsSlice;
