/* eslint-disable no-param-reassign */
/**
 *  Redux toolkit uses immer which allows for state reassignment and direct state mutations. it makes a copy under the hood
 * https://redux-toolkit.js.org/usage/immer-reducers
 */

import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store/Store';

export interface BrandPreferencesState {
  isEditing: boolean;
  isLoadingBrandPreferences: boolean;
  isLoadingUpdatingBrandPreferences: boolean;
  isErrorLoadingBrandPreferences: boolean;
  selectedCategory?: string;
}

const initialState: BrandPreferencesState = {
  isEditing: false,
  isLoadingBrandPreferences: false,
  isLoadingUpdatingBrandPreferences: false,
  isErrorLoadingBrandPreferences: false,
  selectedCategory: undefined,
};

export const BrandPreferencesSlice = createSlice({
  initialState,
  name: 'brandPreferences',
  reducers: {
    setIsEditing(state, { payload }: PayloadAction<boolean>) {
      state.isEditing = payload;
    },
    setIsLoadingBrandPreferences(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingBrandPreferences = payload;
    },
    setIsLoadingUpdateBrandPreferences(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isLoadingUpdatingBrandPreferences = payload;
    },
    setIsErrorLoadingBrandPreferences(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isErrorLoadingBrandPreferences = payload;
    },
    setSelectedCatalogCategory(state, { payload }: PayloadAction<string>) {
      state.selectedCategory = payload;
    },
    resetState() {
      return { ...initialState };
    },
  },
});

export const { reducer: BrandPreferences, actions: BrandPreferencesActions } =
  BrandPreferencesSlice;

export const brandPreferenceLoading = createSelector(
  [
    (state: RootState) => state.BrandPreferences.isLoadingBrandPreferences,
    (state: RootState) =>
      state.BrandPreferences.isLoadingUpdatingBrandPreferences,
  ],
  (isLoadingBrandPreferences, isLoadingUpdatingBrandPreferences) =>
    isLoadingBrandPreferences || isLoadingUpdatingBrandPreferences
);
