import { useAuth0 } from '@auth0/auth0-react';
import React, { useMemo } from 'react';
import {
  MenuAlign,
  SizeString,
  Circle,
  CircleColor,
  Typography,
  BodySize,
  Menu,
  MenuItem,
  useMediaQuery,
  theme,
  icons,
} from '@covetrus/design-system-library';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import type { RootState } from 'redux/store/Store';
import PracticeSelector from '@vrxComponents/PracticeSelector/PracticeSelector';
import usePracticeSelection from '@vrxHooks/usePracticeSelection/usePracticeSelection';
import {
  ContextCode,
  type Auth0ContextUser,
} from '../../hooks/usePracticeSelection/types';
import { useGetMeQuery } from '../../api/generated/graphql';
import TopBarStyled from './styles/TopBarStyled';
import PersonIcon from './assets/PersonIcon';
import {
  LeftItemsStyled,
  RightItemsStyled,
  AvatarGroupStyled,
  AutocompleteStyled,
} from './styles/RightItemsStyled';
import LogoutIcon from './assets/LogoutIcon';
import CovetrusLogo from './assets/Logo_Covetrus_White.png';
import { internalLogoutPath } from '../../constants/Constants';
import PracticeSelectionRadioGroup from './PracticeSelectionRadioGroup';
import { LeftNavActions } from '../../redux/slices/LeftNav.slice';

const TopBar = () => {
  const { contextOptions } = usePracticeSelection();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(
    `(max-width: ${theme.displaySizes.smartphones})`
  );
  const { multipleContexts } = useMemo(() => {
    let hasCorporate = 0;
    let hasPractice = 0;

    for (const context of contextOptions) {
      switch (context.userContextCode) {
        case ContextCode.Corporate:
          hasCorporate += 1;
          break;
        case ContextCode.Practice:
          hasPractice += 1;
          break;
        default:
          break;
      }
    }

    return {
      multipleContexts:
        hasCorporate >= 2 || (hasPractice >= 1 && hasCorporate >= 1),
    };
  }, [contextOptions]);

  const { user } = useAuth0<Auth0ContextUser>();
  const internalUser = useSelector(
    (state: RootState) => state.UserStatus.isInternal
  );
  const practiceUser = useSelector(
    (state: RootState) => state.UserStatus.isPractice
  );

  const leftNavOpen = useSelector((state: RootState) => state.LeftNav.isOpen);
  const intl = useIntl();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { data: getMeData } = useGetMeQuery();

  const doLogout = () => {
    if (internalUser) {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}${internalLogoutPath}`,
        },
      });
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  const firstInitial = getMeData?.getMe?.givenName[0] ?? '';
  const lastInitial = getMeData?.getMe?.familyName[0] ?? '';
  const initials = firstInitial + lastInitial;

  const initialsCircle = (
    <AvatarGroupStyled>
      <Circle
        className='avatar-circle'
        $color={CircleColor.SECONDARY600}
        $size={SizeString.X_SMALL}
        $active
      >
        <Typography.Body size={BodySize.S} className='avatar-circle-initials'>
          {initials}
        </Typography.Body>
      </Circle>
    </AvatarGroupStyled>
  );

  const covetrusLogo = (
    <img
      src={CovetrusLogo}
      alt={intl.formatMessage({ defaultMessage: 'Covetrus Logo' })}
    />
  );

  return (
    <TopBarStyled className='top-bar-cp' role='banner'>
      {isMobile && (
        <LeftItemsStyled
          onClick={() => dispatch(LeftNavActions.setIsOpen(!leftNavOpen))}
        >
          <icons.MenuIcon
            width='24'
            height='24'
            fill={theme.color.neutralWhite}
          />
        </LeftItemsStyled>
      )}
      {user ? (
        /* this hidden input is for dynatrace */
        <input
          hidden
          aria-label={intl.formatMessage({ defaultMessage: 'My Full Name' })}
          id='userNameFullName'
          value={`${user.email} ${user.name}`}
          onChange={() => {}}
        />
      ) : null}
      {isMobile ? (
        <Link key='0' to='/' style={{ color: 'black' }}>
          {covetrusLogo}
        </Link>
      ) : (
        <>{covetrusLogo}</>
      )}
      <RightItemsStyled>
        {!internalUser && !isMobile && (
          <AutocompleteStyled>
            <PracticeSelector isPracticeUser={practiceUser} />
          </AutocompleteStyled>
        )}
        <Menu align={MenuAlign.RIGHT} opener={initialsCircle}>
          <MenuItem
            role='link'
            onSelection={() => navigate('/my-account')}
            icon={<PersonIcon />}
          >
            <Link key='0' to='/my-account' style={{ color: 'black' }}>
              {intl.formatMessage({ defaultMessage: 'My Account' })}
            </Link>
          </MenuItem>
          <MenuItem
            role='link'
            onSelection={() => doLogout()}
            icon={<LogoutIcon />}
          >
            {intl.formatMessage({ defaultMessage: 'Logout' })}
          </MenuItem>
          {multipleContexts && <PracticeSelectionRadioGroup />}
        </Menu>
      </RightItemsStyled>
    </TopBarStyled>
  );
};

export default TopBar;
