/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SortState } from '../../domains/Practice/PracticeSearch/Table/types/SortState';
import { SortDirection } from '../../domains/Practice/PracticeSearch/Table/types/SortState';

export interface PracticeSearchStatus {
  currentPage: number;
  search: string;
  pageSize: number;
  sort: SortState;
}

export interface PracticeSearch {
  practiceSearchStatus: PracticeSearchStatus;
}

const initialState: PracticeSearch = {
  practiceSearchStatus: {
    currentPage: 0,
    search: '',
    pageSize: 10,
    sort: { name: SortDirection.asc },
  },
};

export const PracticeSearchStatusSlice = createSlice({
  initialState,
  name: 'PracticeSearchStatus',
  reducers: {
    setPracticeSearchList(state, action: PayloadAction<PracticeSearch>) {
      state.practiceSearchStatus.currentPage =
        action.payload.practiceSearchStatus.currentPage;
      state.practiceSearchStatus.search =
        action.payload.practiceSearchStatus.search;
      state.practiceSearchStatus.pageSize =
        action.payload.practiceSearchStatus.pageSize;
      state.practiceSearchStatus.sort =
        action.payload.practiceSearchStatus.sort;
    },
  },
});

export const {
  reducer: PracticeSearchStatus,
  actions: PracticeSearchStatusActions,
} = PracticeSearchStatusSlice;
