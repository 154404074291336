import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { PropsWithChildren } from 'react';
import {
  useNavigate as useNavigateOriginal,
  useLocation as useLocationOriginal,
} from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';
import Subject from './Subject';
import type SetSearchParamsWithSelectUpdatesInput from './types/SetSearchParamsWithSelectUpatesInput';
import type SetSearchParamsWithSelectUpdates from './types/SetSearchParamsWithSelectUpdates';
import type SearchParamsWithSelectUpates from './types/SearchParamsWithSelectUpdates';
import type UseSearchParamsWithSelectUpdatesOptions from './types/UseSearchParamsWithSelectUpdatesOptions';

type NavigationContextType = {
  navigateRef: React.RefObject<NavigateFunction> | null;
  locationRef: React.RefObject<Location> | null;
};
export const NavigationContext = createContext<NavigationContextType>({
  navigateRef: null,
  locationRef: null,
});

export const LocationSubject: Subject<Location> = new Subject();

/*
  react-router uses one big context to send changes down the react tree.
  So every route or query param change will re-render the context which will in-turn re-render 
  all the hooks subscribed to react-router context - useNavigate(), useLocation().
  This prevents us from using these hooks as utilities to get latest location or query param value 
  in a component since all the components using these hooks will re-render in addition to the 
  entire Route component re-rendering - which is not ideal.

  With this NavigationContext - we tank the updates from react-router context and
  drill down navigate and location from a separate context.
  This will prevent re-render of consumer components of these hooks for every route change
  and allow using these hooks as utilities instead of context subscribers
*/
const NavigationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigateOriginal();
  const location = useLocationOriginal();

  useEffect(() => {
    LocationSubject.next(location);
  }, [JSON.stringify(location)]);

  // useRef retains object reference between re-renders
  const navigateRef = useRef(navigate);
  const locationRef = useRef(location);

  navigateRef.current = navigate;
  locationRef.current = location;

  // contextValue never changes between re-renders since refs don't change between re-renders
  const contextValue = useMemo(() => {
    return { navigateRef, locationRef };
  }, [locationRef, navigateRef]);

  // since contextValue never changes between re-renders, components/hooks using this context
  // won't re-render when router context updates
  return <NavigationContext value={contextValue}>{children}</NavigationContext>;
};

/* 
  Please be aware: when the url changes - this hook will NOT re-render 
  Only use it as a utility to push url changes into Router history
  which will then re-render the whole route component.
  Eg. const navigate = useNavigateNoUpdates();
*/
export const useNavigateNoUpdates = () => {
  const { navigateRef } = useContext(NavigationContext);
  if (navigateRef === null) {
    throw new Error(
      'RouterUtils context should be added to the React tree right below BrowserRouter for useNavigateNoUpdates hook to work. If you need router in tests or stories, please use WrappedMemoryRouter utility.'
    );
  }
  return navigateRef.current;
};

/* 
  Please be aware: when the url changes - this hook will NOT re-render 
  Only use it as a utility to get latest location object.
  Eg. const location = useLocationNoUpdates();
*/
export const useLocationNoUpdates = () => {
  const { locationRef } = useContext(NavigationContext);
  if (locationRef === null) {
    throw new Error(
      'NavigatorContext should be added to the React tree right below BrowserRouter for useLocationNoUpdates hook to work. If you need router in tests or stories, please use WrappedMemoryRouter utility.'
    );
  }
  return locationRef.current;
};

/* 
  Please be aware: That this hook will only re-render when the subscribingParams changes.
  This hook shares common Observable Subject of LocationSubject; however, the searchParams
  state that is returned from this hook IS NOT SHARED and will be different on each 
*/
export const useSearchParamsWithSelectUpdates = ({
  /** The parameters that are you are subscribing to */
  subscribingParams,
  /** Updates on any parameter change */
  updateOnAnyParamChange,
}: {
  subscribingParams?: Set<string>;
  updateOnAnyParamChange?: boolean;
}): [
  SearchParamsWithSelectUpates,
  SetSearchParamsWithSelectUpdates,
  UseSearchParamsWithSelectUpdatesOptions,
] => {
  const locationFromRef = useLocationNoUpdates();
  const navigate = useNavigateNoUpdates();
  const [stateLocation, setStateLocation] = useState<Location>(
    () => locationFromRef
  );
  const prevParams = useRef<Record<string, string>>(
    Object.fromEntries(
      Object.entries(
        Object.fromEntries(
          new URLSearchParams(locationFromRef.search).entries()
        )
      )
    )
  );

  useEffect(() => {
    const observableFunc = (updatedLocation: Location) => {
      const relevantParams = Object.fromEntries(
        Object.entries(
          Object.fromEntries(
            new URLSearchParams(updatedLocation.search).entries()
          )
        ).filter(
          ([param, _]) =>
            !!updateOnAnyParamChange || !!subscribingParams?.has(param)
        )
      );

      const prevRelevantParams = Object.fromEntries(
        Object.entries(prevParams.current).filter(
          ([param, _]) =>
            !!updateOnAnyParamChange || !!subscribingParams?.has(param)
        )
      );

      if (
        JSON.stringify(relevantParams) !== JSON.stringify(prevRelevantParams)
      ) {
        setStateLocation(updatedLocation);
      }

      prevParams.current = Object.fromEntries(
        new URLSearchParams(updatedLocation.search)
      );
    };

    LocationSubject.subscribe(observableFunc);

    return () => {
      LocationSubject.unsubscribe(observableFunc);
    };
  }, []);

  const isRecordStringString = (record: Record<string, string>) => {
    if (typeof record === 'object' && record !== null) {
      return Object.values(record).every((val) => typeof val === 'string');
    }

    return false;
  };

  const setSearchParams = (params: SetSearchParamsWithSelectUpdatesInput) => {
    if (typeof params === 'function') {
      const updatedParams = params(
        Object.fromEntries(
          new URLSearchParams(window.location.search).entries()
        )
      );
      if (!isRecordStringString(updatedParams)) {
        console.error('Expected Record<string, string>');
        throw new Error('Expected Record<string, string>');
      }
      navigate(
        { search: new URLSearchParams(updatedParams).toString() },
        { replace: true }
      );
    } else {
      navigate(
        { search: new URLSearchParams(params).toString() },
        { replace: true }
      );
    }
  };

  const getScriptPadParamsNoRender = () => {
    return LocationSubject.value?.search
      ? Object.fromEntries(new URLSearchParams(LocationSubject.value.search))
      : prevParams.current;
  };

  return [
    Object.fromEntries(new URLSearchParams(stateLocation?.search)),
    setSearchParams,
    {
      getScriptPadParamsNoRender,
    },
  ];
};

export default NavigationContextProvider;
