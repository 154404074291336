import {
  useFetchPracticePlatformAccountsQuery,
  useFetchStorefrontPreferencesDetailsQuery,
} from '../api/generated/graphql';
import usePracticeSelection from './usePracticeSelection/usePracticeSelection';

const useStorefrontClientUrl = () => {
  const { currentPractice } = usePracticeSelection();
  const {
    data: practicePlatformAccountsData,
    loading: practicePlatformAccountsLoading,
    error: practicePlatformAccountsError,
  } = useFetchPracticePlatformAccountsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      practicePlatformAccountsInput: {
        animalCareBusinessKey: currentPractice.value as string,
      },
    },
    skip: !currentPractice.value,
  });

  if (practicePlatformAccountsError) {
    console.error(practicePlatformAccountsError);
  }

  const {
    data: storefrontPrefencesData,
    loading: storefrontPrefencesLoading,
    error: storefrontPrefencesError,
  } = useFetchStorefrontPreferencesDetailsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      platformAccountInput: {
        platformAccountKey: practicePlatformAccountsData
          ?.getPracticePlatformAccounts[0]?.platformAccountKey as string,
        animalCareBusinessKey: currentPractice.value as string,
      },
    },
    skip: !practicePlatformAccountsData,
  });

  if (storefrontPrefencesError) {
    console.error(storefrontPrefencesError);
  }

  const loading = practicePlatformAccountsLoading || storefrontPrefencesLoading;

  return {
    loading,
    storefrontUrl: storefrontPrefencesData?.readPlatformAccount.storefrontUrl,
  };
};

export default useStorefrontClientUrl;
