enum FeatureFlags {
  additionalEmails = 'additionalEmails',
  rxHistoryExport = 'rxHistoryExport',
  editVisibleOnStorefront = 'editVisibleOnStorefront',
  additionalUserEmails = 'additionalUserEmails',
  smsOptInFeature = 'smsOptInFeature',
  notificationEmailAddresses = 'notificationEmailAddresses',
  legacyOrderHistory = 'legacyOrderHistory',
  corporateSignin = 'corporateSignin',
  promotionalPricing = 'promotionalPricing',
  rxAccessCard = 'rxAccessCard',
  externalUserEditPracticeProductCatalog = 'externalUserEditPracticeProductCatalog',
  lastModifiedCatalogManagement = 'lastModifiedCatalogManagement',
}

/** This holds the dynamic feature flags. This will need to be used when we implement
 * a more generalized way of fetching the feature flags from one service,
 * instead of making multiple queries
 */
export enum DynamicFeatureFlags {
  COMPETITOR_PRICING = 'vrxpro.retailerPricing',
  RXACCESS_PARTNER_API_INTERNAL = 'vrxpro.rxaccess.partner.api.internal',
  LAST_MODIFIED_CATALOG_MANAGEMENT = 'lastModifiedCatalogManagement',
  SMS_OPT_IN_FEATURE = 'smsOptInFeature',
  ADDITIONAL_CLIENT_EMAILS = 'additionalClientEmails',
  ADDITIONAL_USER_EMAILS = 'additionalUserEmails',
  EDIT_VISIBLE_ON_STOREFRONT = 'editVisibleOnStorefront',
  RX_ACCESS_SETTINGS = 'rxAccessSettings',
  LEGACY_ORDER_HISTORY = 'legacyOrderHistory',
  RX_HISTORY_EXPORT = 'rxHistoryExport',
  VMG_ELITE_PSMC = 'vrxpro.vmgElitePsmc',
  ASSEMBLY_TYPE = 'vrxpro.product.assemblyTypeEnabled',
  HELP_CLIENT_MANAGE_AUTOSHIP = 'vrxpro.helpclient.manageautoships',
  HELP_CLIENT_ORDER_RX = 'vrxpro.helpclient.orderrx',
  RX_ACCESS_PARTNER_PATIENT_LINKING = 'vrxpro.rxaccess.partner.patientLinkingEnabled'
}

enum Environment {
  local = 'local',
  dev = 'dev',
  qa = 'qa',
  stage = 'stage',
  prod = 'prod',
}

// Environments that should have the feature enabled
export const features: Record<FeatureFlags, Array<string>> = {
  /** relates to client additional emails */
  [FeatureFlags.additionalEmails]: [], // Hide additional emails and types
  [FeatureFlags.rxHistoryExport]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
  ],
  [FeatureFlags.editVisibleOnStorefront]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
  ],
  [FeatureFlags.additionalUserEmails]: [],
  [FeatureFlags.smsOptInFeature]: [],
  [FeatureFlags.notificationEmailAddresses]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
    Environment.stage,
    Environment.prod,
  ],
  [FeatureFlags.legacyOrderHistory]: [
    // Environment.local,
    Environment.dev,
    Environment.qa,
  ],
  [FeatureFlags.corporateSignin]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
    Environment.stage,
    Environment.prod,
  ],
  [FeatureFlags.promotionalPricing]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
    Environment.stage,
    Environment.prod,
  ],
  [FeatureFlags.rxAccessCard]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
    Environment.stage,
    Environment.prod,
  ],
  [FeatureFlags.externalUserEditPracticeProductCatalog]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
    Environment.stage,
    Environment.prod,
  ],
  [FeatureFlags.lastModifiedCatalogManagement]: [
    Environment.local,
    Environment.dev,
    Environment.qa,
  ],
};
