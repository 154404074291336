import { makeVar, useReactiveVar } from '@apollo/client';
import useAsyncError from '@vrxHooks/useAsyncError';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type { OptionProps } from '@covetrus/design-system-library';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import {
  useGetMeLazyQuery,
  useSearchCorporatePracticesLazyQuery,
} from '../../api/generated/graphql';
import config from '../../config/config';
import { ContextCode } from './types';
import { UserActions } from '../../redux/slices/UserStatus.slice';
import practiceOptionsFromContextList from './practiceOptionsFromContextList';

export type PracticeOption = OptionProps<string> & { contextKey: string };

export const reactiveCurrentPractice = makeVar<PracticeOption>({
  label: '',
  value: '',
  contextKey: '',
});

export const reactiveCurrentContext = makeVar<UserContext>({
  userContextCode: '',
  contextKey: '',
  contextDescription: '',
});

export const reactiveContextOptions = makeVar<Array<UserContext>>([]);
const reactivePracticeOptions = makeVar<Array<PracticeOption>>([]);
const reactiveCorporateGroupOptions = makeVar<Array<PracticeOption>>([]);
const reactiveLoading = makeVar<boolean>(true);
const reactiveCorporateLocationsLoading = makeVar<boolean>(false);
const reactivePracticeName = makeVar<string>('');
const ACTIVE_PRACTICE_COOKIE = 'practiceKey';
const ACTIVE_CONTEXT_COOKIE = 'contextKey';

export interface UserContext {
  animalCareBusinessKey?: string;
  animalCareBusinessOrganizationKey?: string;
  userContextCode: ContextCode | string;
  contextKey: string;
  contextDescription: string;
}

interface GetContextsResponse {
  contexts: Array<UserContext>;
}

const usePracticeSelection = () => {
  const practiceOptions = useReactiveVar(reactivePracticeOptions);
  const corporateGroupOptions = useReactiveVar(reactiveCorporateGroupOptions);
  const contextOptions = useReactiveVar(reactiveContextOptions);
  const [axiosError, setAxiosError] = useState(false);
  const throwError = useAsyncError();
  const loading = useReactiveVar(reactiveLoading);
  const practiceName = useReactiveVar(reactivePracticeName);
  const {
    getAccessTokenSilently,
    isLoading: isAuth0Loading,
    isAuthenticated,
  } = useAuth0();
  const dispatch = useDispatch();
  const currentContext = useReactiveVar(reactiveCurrentContext);
  const currentPractice = useReactiveVar(reactiveCurrentPractice);

  const [getMe] = useGetMeLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      reactiveLoading(false);
    },
    onError: (e) => {
      throwError(e);
    },
  });
  const [searchCorporatePractices] = useSearchCorporatePracticesLazyQuery({
    fetchPolicy: 'network-only',
    onError: (e) => {
      throwError(e);
    },
  });

  useEffect(() => {
    if (loading && !isAuth0Loading && isAuthenticated) {
      getAccessTokenSilently().then((auth0Token: string) => {
        return axios
          .get(
            `${
              config[process.env.REACT_APP_ENV || 'local']?.contexts
            }/getContexts`,
            {
              headers: {
                authorization: `Bearer ${auth0Token}`,
                'cache-control': 'no-cache',
              },
            }
          )
          .then(async (res: AxiosResponse<GetContextsResponse>) => {
            // set context key options.
            reactiveContextOptions(res.data.contexts);

            // select context based on priority
            const context = selectContext();

            if (context) {
              switch (context.userContextCode) {
                case ContextCode.Corporate:
                  reactiveCorporateLocationsLoading(true);
                  await getPracticeLocations(context);
                  reactiveCorporateLocationsLoading(false);
                  break;
                case ContextCode.Practice:
                  // if the current context is a practice
                  derivePracticeUserPracticeOptions();
                  reactiveCurrentPractice({
                    contextKey: context.contextKey,
                    label: context.contextDescription,
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    value: context.animalCareBusinessKey as string,
                  });
                  break;
                default:
                  break;
              }
              getMe();
            }
          })
          .catch((err) => {
            console.error(err);
            unstable_batchedUpdates(() => {
              reactiveLoading(false);
              setAxiosError(true);
            });
          });
      });
    }
  }, [isAuth0Loading, isAuthenticated]);

  /**
   * This use effect is used to ensure the active practice selection is
   * within the practice options list.
   */
  useEffect(() => {
    if (
      !practiceOptions.find(
        (practiceOption) => practiceOption.value === currentPractice.value
      )
    ) {
      selectPractice();
    }
  }, [currentPractice, practiceOptions]);

  if (axiosError) {
    throw new Error(`Failed to fetch user contexts`);
  }

  const derivePracticeUserPracticeOptions = () => {
    const contexts = reactiveContextOptions();
    const practiceOpts = practiceOptionsFromContextList(contexts);
    reactivePracticeOptions(practiceOpts);
  };

  const selectPractice = (practiceKey?: string) => {
    const currContext = reactiveCurrentContext();

    // this is to ensure the practice options are correct.
    if (currContext.userContextCode === ContextCode.Practice) {
      derivePracticeUserPracticeOptions();
    }

    const practiceOpts = reactivePracticeOptions();

    // to reduce duplicate code, I created a simple function to
    // handle the practice selection.
    function handleSelection(selectedPractice?: PracticeOption) {
      if (selectedPractice) {
        if (currContext.contextKey !== selectedPractice.contextKey) {
          selectContext(selectedPractice.contextKey);
        }
        reactiveCurrentPractice(selectedPractice);
        localStorage.setItem(ACTIVE_PRACTICE_COOKIE, selectedPractice.value);
        getMe({
          fetchPolicy: 'network-only',
        });
      } else {
        console.error(`Error selecting practice, '${practiceKey}'`);
      }
    }

    if (practiceOpts.length) {
      // if practiceKey is passed, select it from the list of options.
      if (practiceKey) {
        const selectedPractice = practiceOpts.find(
          (practiceOption) => practiceOption.value === practiceKey
        );
        handleSelection(selectedPractice);
      }
      // else a practice key is not passed in.
      else {
        // get the cookie from local storage
        const cookiePractice = localStorage.getItem(ACTIVE_PRACTICE_COOKIE);
        const prevPracticeSelection = practiceOpts.find(
          ({ value }) => value === cookiePractice
        );

        // prioritize local storage selection
        if (prevPracticeSelection) {
          handleSelection(prevPracticeSelection);
        }
        // select the practice passed on the context key for practice users.
        else if (currContext.userContextCode === ContextCode.Practice) {
          const selectedPractice = practiceOpts.find(
            (opt) => opt.value === currContext.animalCareBusinessKey
          );
          handleSelection(selectedPractice);
        }
        // select the first option for corporate users.
        else if (currContext.userContextCode === ContextCode.Corporate) {
          handleSelection(practiceOpts[0]);
        }
      }
    }
  };

  // for corporate groups only, get practice locations.
  const getPracticeLocations = (context: UserContext) => {
    if (context.userContextCode === ContextCode.Corporate) {
      return searchCorporatePractices({
        fetchPolicy: 'network-only',
        variables: {
          searchInput: {
            animalCareBusinessOwnerKey:
              context.animalCareBusinessOrganizationKey as string,
            query: '',
            page: 0,
            size: 1000,
            isActiveOnly: true,
            isStorefrontActiveOnly: true,
          },
        },
      }).then(({ data }) => {
        if (data) {
          // map practices for vRxPro dropdown.
          const practiceOpts = data?.searchCorporatePractices.practices.map(
            (practice) =>
              ({
                label: practice.name,
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                value: practice.animalCareBusinessKey as string,
                // map all practices to the corporate user's contextKey.
                contextKey: context.contextKey,
              }) as PracticeOption
          );

          // persist the options
          reactivePracticeOptions(practiceOpts);
          // if a practice is not selected, select one.
          if (!reactiveCurrentPractice().contextKey) {
            selectPractice();
          }
          return practiceOpts;
        }
        return [];
      });
    }
    return Promise.resolve([]);
  };

  const forceSelectPractice = (option: PracticeOption) => {
    reactiveCurrentPractice(option);
  };

  const selectContext = (contextKey?: string) => {
    // get context options
    const ctxOptions = reactiveContextOptions();

    function updateUserActions(context: UserContext) {
      let userIsCorporate = false;
      let userIsInternal = false;
      let userIsPractice = false;

      switch (context.userContextCode) {
        case ContextCode.Corporate:
          userIsCorporate = true;
          break;
        case ContextCode.Internal:
          userIsInternal = true;
          break;
        case ContextCode.Practice:
          userIsPractice = true;
          break;
        default:
          break;
      }

      dispatch(
        UserActions.setUserStatuses({
          isCorporate: userIsCorporate,
          isPractice: userIsPractice,
          isInternal: userIsInternal,
        })
      );
    }

    // if options are available.
    if (ctxOptions.length) {
      // get value from local storage.
      const contextKeyCookie = localStorage.getItem(ACTIVE_CONTEXT_COOKIE);
      // tmp variable for selecting a context.
      let selectedContext: UserContext | undefined;

      // prioritize incoming contextKey request.
      // if a context is provied, attempt to select it.
      if (contextKey) {
        selectedContext = ctxOptions.find((c) => c.contextKey === contextKey);
      }
      // else if a context is stored in local storage, attempt to select it.
      else if (contextKeyCookie) {
        selectedContext = ctxOptions.find(
          (c) => c.contextKey === contextKeyCookie
        );
      }

      // if the context key was not found nor provided...
      if (!selectedContext) {
        // filter contexts
        const corporateContexts = ctxOptions.filter(
          (c) => c.userContextCode === ContextCode.Corporate
        );
        const internalContexts = ctxOptions.filter(
          (c) => c.userContextCode === ContextCode.Internal
        );
        const practiceContexts = ctxOptions.filter(
          (c) => c.userContextCode === ContextCode.Practice
        );

        // prioritize contexts like so:
        //   internal > practice > corporate
        if (internalContexts.length) {
          const [firstContext] = internalContexts;
          selectedContext = firstContext;
        } else if (practiceContexts.length) {
          const [firstContext] = practiceContexts;
          selectedContext = firstContext;
        } else if (corporateContexts.length) {
          const [firstContext] = corporateContexts;
          selectedContext = firstContext;
        }
      }

      // once a context is selected, save and return it.
      if (selectedContext) {
        updateUserActions(selectedContext);
        localStorage.setItem(ACTIVE_CONTEXT_COOKIE, selectedContext.contextKey);
        reactiveCurrentContext(selectedContext);
        return selectedContext;
      }

      throw Error(`Something went wrong selecting a context.`);
    }

    throw Error(`No contexts found for user.`);
  };

  return {
    error: axiosError,
    loading,
    selectPractice,
    selectContext,
    currentContext,
    currentPractice,
    practiceOptions,
    corporateGroupOptions,
    contextOptions,
    forceSelectPractice,
    practiceName,
    reactivePracticeName,
    getPracticeLocations,
  };
};

export default usePracticeSelection;
