/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line max-len
import type MenuItem from '@covetrus/design-system-library/dist/components/LeftNavigation/types/MenuItem';
import {
  useParams,
  useLocation,
  Link,
  createSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  LeftNavigation as LeftNav,
  icons as Icons,
  Typography,
  BodySize,
  theme,
  useMediaQuery,
  ContainerLoader,
} from '@covetrus/design-system-library';
import {
  useFetchDataConnectivityDetailsQuery,
  useFetchPracticePlatformAccountsQuery,
  useGetLeftNavCounterQuery,
  useGetMeQuery,
} from '@api/generated/graphql';
import { Permission } from '@covetrus/reference-data';
import type { LeftNavigationProps } from './types/LeftNavigationProps';
import { LeftNavActions } from '../../redux/slices/LeftNav.slice';
import DashboardIcon from './assets/DashboardIcon';
import PracticeSearchIcon from './assets/PracticeSearchIcon';
import usePermissions from '../../hooks/usePermissions';
import ResemblesLinkStyled from './styles/ResemblesLinkStyled';
import SubCategoryStyled from './styles/SubCategoryStyled';
import type { RootState } from '../../redux/store/Store';
import type PracticeKeyParams from './types/PracticeKeyParams';
import DisableLinkStyled from './styles/DisableLinkStyled';
import ManageDraftsMenuItem from './subcomponents/ManageDraftsMenuItem';
import useScriptPadParams from '../../domains/Patient/CreateRx/hooks/useScriptPadParams';
import StorefrontLinkIcon from './assets/StorefrontLinkIcon';
import PracticeSearchStyled from './styles/PracticeSearchStyled';
import PracticeNameStyled from './styles/PracticeNameStyled';
import usePracticeSelection from '../../hooks/usePracticeSelection/usePracticeSelection';
import LeftNavIcon from './subcomponents/LeftNavIcon';
import MenuItemWithCounter from './subcomponents/MenuItemWithCounter';

const LeftNavigation: React.FC<LeftNavigationProps> = ({ storefrontUrl }) => {
  const intl = useIntl();
  const { acbKey } = useParams<keyof PracticeKeyParams>() as PracticeKeyParams;

  const internalUser = useSelector(
    (state: RootState) => state.UserStatus.isInternal
  );

  const {
    data: dataCounter,
    loading: loadingCounter,
    error: errorCounter,
  } = useGetLeftNavCounterQuery({
    notifyOnNetworkStatusChange: true,
    skip: internalUser,
  });
  const {
    data: getMeData,
    error: getMeError,
    loading: getMeLoading,
  } = useGetMeQuery();

  const { getScriptPadParams } = useScriptPadParams({
    subscribingParams: new Set(['acbKey']),
  });
  const params = getScriptPadParams();
  const { state: statePath, pathname } = useLocation();
  const { currentPractice, reactivePracticeName } = usePracticeSelection();
  const dispatch = useDispatch();
  const location = useLocation();

  const topPosition = 64;
  const {
    error: usePermissionsError,
    loading: usePermissionsLoading,
    userManagement,
    readDrafts,
    manageDrafts,
    readPracticeUser,
    readPrescription,
    managePrescription,
    managePrescriptionPharmTech,
    readPractice,
    readProducts,
    readPracticePricing,
    readClient,
    manageClient,
    manageStorefront,
    readStorefront,
    readReportsKPI,
    readReportsAggregate,
    readReportsFinancial,
    readOrderHistory,
    managePracticeProductCatalogPricing,
    managePatients,
    hasPermission,
    hasAnyPermissionOnList,
  } = usePermissions();

  const leftNavOpen = useSelector((state: RootState) => state.LeftNav.isOpen);
  const isMobile = useMediaQuery(
    `(max-width: ${theme.displaySizes.smartphones})`
  );

  const previousLocation = useRef(location);
  useEffect(() => {
    if (isMobile && previousLocation.current.pathname !== location.pathname) {
      dispatch(LeftNavActions.setIsOpen(false));
    }
  }, [location.pathname]);

  let storefrontUrlUpdated = '';
  if (
    storefrontUrl &&
    (storefrontUrl.startsWith('http://') ||
      storefrontUrl.startsWith('https://'))
  ) {
    storefrontUrlUpdated = storefrontUrl;
  } else {
    storefrontUrlUpdated = `https://${storefrontUrl}`;
  }
  const isNoAcbKey = !acbKey && !currentPractice.value && !params.acbKey;
  const {
    data: practicePlatformAccountsData,
    loading: practicePlatformAccountsLoading,
    error: practicePlatformAccountsError,
  } = useFetchPracticePlatformAccountsQuery({
    variables: {
      practicePlatformAccountsInput: {
        animalCareBusinessKey: acbKey || params.acbKey || currentPractice.value,
      },
    },
    skip: isNoAcbKey,
  });

  const {
    data: dataConnectivityData,
    loading: dataConnectivityLoading,
    error: dataConnectivityError,
    refetch: refetchDataConnectivity,
  } = useFetchDataConnectivityDetailsQuery({
    variables: {
      platformAccountInput: {
        platformAccountKey: practicePlatformAccountsData
          ?.getPracticePlatformAccounts[0]?.platformAccountKey as string,
        animalCareBusinessKey: acbKey || currentPractice.value || params.acbKey,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip:
      !practicePlatformAccountsData ||
      !practicePlatformAccountsData.getPracticePlatformAccounts[0]
        ?.platformAccountKey ||
      isNoAcbKey,
    notifyOnNetworkStatusChange: true,
  });

  // Add a useEffect to re-fetch when key dependencies change
  useEffect(() => {
    if (
      practicePlatformAccountsData?.getPracticePlatformAccounts[0]
        ?.platformAccountKey &&
      !isNoAcbKey
    ) {
      refetchDataConnectivity();
    }
  }, [
    acbKey,
    currentPractice.value,
    params.acbKey,
    practicePlatformAccountsData,
    refetchDataConnectivity,
  ]);

  const memoInformation = useMemo(
    () => [
      acbKey,
      currentPractice.value,
      params.acbKey,
      internalUser,
      getMeData?.getMe?.userKey,
      dataCounter?.pendingApprovalsCount,
      dataCounter?.pendingRenewalsCount,
      pathname,
      dataConnectivityData?.readPlatformAccount?.isWritebackEnabled,
    ],
    [
      acbKey,
      currentPractice.value,
      params.acbKey,
      internalUser,
      getMeData?.getMe?.userKey,
      dataCounter?.pendingApprovalsCount,
      dataCounter?.pendingRenewalsCount,
      pathname,
      dataConnectivityData?.readPlatformAccount?.isWritebackEnabled,
    ]
  );

  const canSeeLinkingCenter =
    manageClient &&
    managePatients &&
    dataConnectivityData?.readPlatformAccount?.isWritebackEnabled;

  const practiceName =
    (statePath as string) ||
    currentPractice.label ||
    practicePlatformAccountsData?.getPracticePlatformAccounts[0].displayName;
  const pimsInstallationSettings =
    dataConnectivityData?.readPlatformAccount
      ?.platformAccountPimsInstallationSettings || [];

  useEffect(() => {
    if (!currentPractice.label) {
      reactivePracticeName(practiceName);
    }
  }, [reactivePracticeName, practiceName, currentPractice.label]);

  const practiceUsersLinks = [
    {
      icon:
        acbKey || params.acbKey ? (
          <Link to={`/users/${acbKey || params.acbKey}`} state={practiceName}>
            <LeftNavIcon icon={Icons.UsersIcon} />
          </Link>
        ) : (
          <LeftNavIcon icon={Icons.UsersIcon} />
        ),
      initiallySelected: pathname.includes('users'),
      menuItem: (
        <SubCategoryStyled key='1'>
          {acbKey || params.acbKey ? (
            <Link to={`/users/${acbKey || params.acbKey}`} state={practiceName}>
              {intl.formatMessage({
                defaultMessage: 'Practice Users',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Practice Users',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const createRx = {
    icon: (
      <Link
        to={{
          pathname: `/create-rx`,
          search: createSearchParams({
            sourceRoute: location.pathname,
          }).toString(),
        }}
        state={practiceName}
      >
        <LeftNavIcon icon={Icons.CreateRxIcon} />
      </Link>
    ),
    initiallySelected: pathname.includes('create-rx'),
    menuItem: (
      <SubCategoryStyled key='1'>
        <Link
          to={{
            pathname: `/create-rx`,
            search: createSearchParams({
              sourceRoute: location.pathname,
            }).toString(),
          }}
          state={practiceName}
        >
          {intl.formatMessage({
            defaultMessage: 'Create Rx',
          })}
        </Link>
      </SubCategoryStyled>
    ),
  };

  const clientRequests = {
    optionalId: 'client-requests',
    icon: (
      <Link
        to={{
          pathname: `/client-requests`,
          search: createSearchParams({
            sourceRoute: location.pathname,
          }).toString(),
        }}
        state={practiceName}
      >
        <LeftNavIcon icon={Icons.RequestIcon} />
      </Link>
    ),
    initiallySelected: pathname.includes('client-requests'),
    menuItem: (
      <SubCategoryStyled key='1'>
        <MenuItemWithCounter
          label={intl.formatMessage({
            defaultMessage: 'Client Requests',
          })}
          url='/client-requests'
          count={dataCounter?.pendingApprovalsCount}
          hide={pathname === '' || pathname === '/'}
          practiceNameState={practiceName}
          loading={loadingCounter}
        />
      </SubCategoryStyled>
    ),
  };

  const proscriptRenewals = {
    icon: (
      <Link
        to={{
          pathname: `/proscript-renewals`,
          search: createSearchParams({
            sourceRoute: location.pathname,
          }).toString(),
        }}
        state={practiceName}
      >
        <LeftNavIcon icon={Icons.RenewalsIcon} />
      </Link>
    ),
    initiallySelected: pathname.includes('proscript-renewals'),
    menuItem: (
      <SubCategoryStyled key='1'>
        <MenuItemWithCounter
          label={intl.formatMessage({
            defaultMessage: 'ProScript Renewals',
          })}
          url='/proscript-renewals'
          count={dataCounter?.pendingRenewalsCount}
          hide={pathname === '' || pathname === '/'}
          practiceNameState={practiceName}
          loading={loadingCounter}
        />
      </SubCategoryStyled>
    ),
  };

  const practiceClientsLinks = [
    {
      icon:
        acbKey || params.acbKey ? (
          <Link
            to={`/client-list/${acbKey || params.acbKey}`}
            state={practiceName}
          >
            <LeftNavIcon icon={Icons.UsersIcon} />
          </Link>
        ) : (
          <LeftNavIcon icon={Icons.UsersIcon} />
        ),
      initiallySelected: pathname.includes('client-list'),
      menuItem: (
        <SubCategoryStyled key='2'>
          {acbKey || params.acbKey ? (
            <Link
              to={`/client-list/${acbKey || params.acbKey}`}
              state={practiceName}
            >
              {intl.formatMessage({
                defaultMessage: 'Client List',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Client List',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const practiceClientRequestsLinks = [
    {
      icon:
        acbKey || params.acbKey ? (
          <Link
            to={`/client-requests/${acbKey || params.acbKey}`}
            state={practiceName}
          >
            <LeftNavIcon icon={Icons.RequestIcon} />
          </Link>
        ) : (
          <LeftNavIcon icon={Icons.RequestIcon} />
        ),
      initiallySelected: pathname.includes('client-requests'),
      menuItem: (
        <SubCategoryStyled key='3'>
          {acbKey || params.acbKey ? (
            <Link
              to={`/client-requests/${acbKey || params.acbKey}`}
              state={practiceName}
            >
              {intl.formatMessage({
                defaultMessage: 'Client Requests',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Client Requests',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const practiceOrderHistoryLinks = [
    {
      icon:
        acbKey || params.acbKey ? (
          <Link
            to={`/order-history/${acbKey || params.acbKey}`}
            state={practiceName}
          >
            <LeftNavIcon icon={Icons.HistoryIcon} />
          </Link>
        ) : (
          <LeftNavIcon icon={Icons.HistoryIcon} />
        ),
      initiallySelected: pathname.includes('order-history'),
      menuItem: (
        <SubCategoryStyled key='3'>
          {acbKey || params.acbKey ? (
            <Link
              to={`/order-history/${acbKey || params.acbKey}`}
              state={practiceName}
            >
              {intl.formatMessage({
                defaultMessage: 'Order History',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Order History',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const practiceReportingLinks: Array<MenuItem> = [
    {
      menuItem: (
        <ResemblesLinkStyled>
          {intl.formatMessage({ defaultMessage: 'Reporting' })}
        </ResemblesLinkStyled>
      ),
      icon:
        acbKey || params.acbKey ? (
          <LeftNavIcon icon={Icons.ReportingIcon} />
        ) : (
          <LeftNavIcon icon={Icons.ReportingIcon} />
        ),
      children: [
        {
          icon: <></>,
          initiallySelected: pathname.includes('reporting'),
          menuItem: (
            <Link
              key='monthly-reports'
              to={`/reporting/${acbKey || params.acbKey}`}
            >
              {intl.formatMessage({ defaultMessage: 'Monthly Reports' })}
            </Link>
          ),
        },
        ...(readReportsKPI
          ? [
              {
                icon: <></>,
                initiallySelected: pathname.includes('custom-reports'),
                menuItem: (
                  <Link
                    key='custom-reports'
                    to={`/custom-reports/${acbKey || params.acbKey}`}
                  >
                    {intl.formatMessage({ defaultMessage: 'Custom Reports' })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(readReportsAggregate
          ? [
              {
                icon: <></>,
                initiallySelected: pathname.includes('analytics-dashboard'),
                menuItem: (
                  <Link
                    key='analytics-dashboard'
                    to={`/analytics-dashboard/${acbKey || params.acbKey}`}
                  >
                    {intl.formatMessage({
                      defaultMessage: 'Analytics Dashboard',
                    })}
                  </Link>
                ),
              },
            ]
          : []),
      ],
    },
  ];

  const rxHistory = [
    {
      icon:
        acbKey || params.acbKey ? (
          <Link
            to={`/rx-history/${acbKey || params.acbKey}`}
            state={practiceName}
          >
            <LeftNavIcon icon={Icons.HistoryIcon} />
          </Link>
        ) : (
          <LeftNavIcon icon={Icons.HistoryIcon} />
        ),
      initiallySelected: pathname.includes('rx-history'),
      menuItem: (
        <SubCategoryStyled key='5'>
          {acbKey || params.acbKey ? (
            <Link
              to={`/rx-history/${acbKey || params.acbKey}`}
              state={practiceName}
            >
              {intl.formatMessage({
                defaultMessage: 'Rx History',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Rx History',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const productCatalog = [
    {
      icon:
        acbKey || params.acbKey ? (
          <Link
            to={`/product-catalog/${acbKey || params.acbKey}`}
            state={practiceName}
          >
            <LeftNavIcon icon={Icons.CatalogIcon} />
          </Link>
        ) : (
          <LeftNavIcon icon={Icons.CatalogIcon} />
        ),
      initiallySelected: pathname.includes('product-catalog'),
      menuItem: (
        <SubCategoryStyled key='5'>
          {acbKey || params.acbKey ? (
            <Link
              to={`/product-catalog/${acbKey || params.acbKey}`}
              state={practiceName}
            >
              {intl.formatMessage({
                defaultMessage: 'Product Catalog',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Product Catalog',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const renewals = [
    {
      icon: <LeftNavIcon icon={Icons.CatalogIcon} />,
      initiallySelected: pathname.includes('renewals'),
      menuItem: (
        <SubCategoryStyled key='5'>
          {acbKey || params.acbKey ? (
            <Link
              to={`/proscript-renewals/${acbKey || params.acbKey}`}
              state={practiceName}
            >
              {intl.formatMessage({
                defaultMessage: 'ProScript Renewals',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'ProScript Renewals',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const linkingCenter = [
    {
      icon: (
        <Link to={`/linking/patient/list/${acbKey || params.acbKey || ''}`}>
          <LeftNavIcon icon={Icons.LinkingCenterIcon} />
        </Link>
      ),
      initiallySelected: pathname.includes('linking/patient/list'),
      menuItem: (
        <SubCategoryStyled key='5'>
          <Link to={`/linking/patient/list/${acbKey || params.acbKey || ''}`}>
            {intl.formatMessage({
              defaultMessage: 'Linking Center',
            })}
          </Link>
        </SubCategoryStyled>
      ),
    },
  ];

  const practiceManageDraftsLinks = [
    {
      icon:
        acbKey || params.acbKey ? (
          <LeftNavIcon icon={Icons.PencilIcon} />
        ) : (
          <></>
        ),
      initiallySelected: pathname.includes('manage-drafts'),
      menuItem: (
        <SubCategoryStyled key='5'>
          {acbKey || params.acbKey ? (
            <ManageDraftsMenuItem acbKey={acbKey || params.acbKey} />
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Manage Drafts',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const catalogManagement = [
    {
      icon:
        acbKey || params.acbKey ? (
          <LeftNavIcon icon={Icons.CatalogIcon} />
        ) : (
          <LeftNavIcon icon={Icons.CatalogIcon} />
        ),
      initiallySelected: pathname.includes('catalog-management'),
      menuItem: (
        <SubCategoryStyled key='5'>
          {acbKey || params.acbKey ? (
            <Link
              to={`/catalog-management/${acbKey || params.acbKey}`}
              state={practiceName}
            >
              {intl.formatMessage({
                defaultMessage: 'Catalog Management',
              })}
            </Link>
          ) : (
            <DisableLinkStyled>
              {intl.formatMessage({
                defaultMessage: 'Catalog Management',
              })}
            </DisableLinkStyled>
          )}
        </SubCategoryStyled>
      ),
    },
  ];

  const practiceSettingsInternal = {
    menuItem: (
      <Link
        to={`/internal-user/practice-settings/${acbKey || params.acbKey}`}
        state={practiceName}
      >
        {intl.formatMessage({ defaultMessage: 'Practice Settings' })}
      </Link>
    ),
    icon: <LeftNavIcon icon={Icons.SettingsIcon} />,
    initiallySelected:
      pathname.includes('practice-settings') ||
      pathname.includes('custom-reports'),
  };

  const practiceSettingsExternal = (
    <DisableLinkStyled>
      <LeftNavIcon icon={Icons.SettingsIcon} />
      {intl.formatMessage({ defaultMessage: 'Practice Settings' })}
    </DisableLinkStyled>
  );

  const internalTopLevelPracticeSearchMenu = [
    {
      menuItem: hasPermission(Permission.READ_PRACTICE) ? (
        <Link to='/' style={{ width: 'inherit' }}>
          <PracticeSearchStyled>
            {intl.formatMessage({ defaultMessage: 'Practice Search' })}
          </PracticeSearchStyled>
        </Link>
      ) : null,
      icon: (
        <Link to='/'>
          <LeftNavIcon icon={Icons.MagnifyingGlass} />
        </Link>
      ),
      initiallySelected: pathname === '' || pathname === '/',
    },
  ];

  const internalTopLevelPartnerApiKeyMenu =
    hasPermission(Permission.MANAGE_RXACCESS_PARTNER) ||
    hasPermission(Permission.READ_RXACCESS_PARTNER)
      ? [
          {
            menuItem: (
              <Link
                to='/internal-user/partner-api-keys'
                style={{ width: 'inherit' }}
              >
                <PracticeSearchStyled>
                  {intl.formatMessage({ defaultMessage: 'Manage API Keys' })}
                </PracticeSearchStyled>
              </Link>
            ),
            icon: (
              <Link to='/internal-user/partner-api-keys'>
                <LeftNavIcon icon={Icons.SettingsIcon} />
              </Link>
            ),
            initiallySelected: pathname.includes('partner-api-keys'),
          },
        ]
      : [];

  const internalUserMenuItems = hasPermission(Permission.READ_PRACTICE)
    ? useMemo(
        () => [
          {
            menuItem: hasPermission(Permission.READ_PRACTICE) ? (
              <Link to='/' style={{ width: 'inherit' }}>
                <PracticeSearchStyled>
                  {intl.formatMessage({ defaultMessage: 'Practice Search' })}
                </PracticeSearchStyled>
              </Link>
            ) : null,
            icon: <LeftNavIcon icon={Icons.MagnifyingGlass} />,
            initiallySelected: pathname.includes('internal-user'),
          },
          {
            menuItem: (
              <div>
                <Typography.Body
                  size={BodySize.XS}
                  style={{ color: theme.color.neutral600, margin: '0px' }}
                >
                  {intl.formatMessage({
                    defaultMessage: 'Practice View',
                  })}
                </Typography.Body>
                <PracticeNameStyled>{practiceName}</PracticeNameStyled>
              </div>
            ),
            icon: <LeftNavIcon icon={PracticeSearchIcon} />,
            children: [
              acbKey || params.acbKey
                ? practiceSettingsInternal
                : practiceSettingsExternal,
              ...(readPracticeUser || userManagement ? practiceUsersLinks : []),
              ...(readClient || manageClient ? practiceClientsLinks : []),
              ...(readPrescription ||
              managePrescriptionPharmTech ||
              managePrescription
                ? practiceClientRequestsLinks
                : []),
              ...(readOrderHistory ? practiceOrderHistoryLinks : []),
              ...(readReportsAggregate || readReportsFinancial || readReportsKPI
                ? practiceReportingLinks
                : []),
              ...(readPrescription ? rxHistory : []),
              ...(readDrafts || manageDrafts ? practiceManageDraftsLinks : []),
              ...(readPractice && readProducts ? productCatalog : []),
              ...(readDrafts ? renewals : []),
              ...(dataConnectivityData?.readPlatformAccount
                ?.isWritebackEnabled && pimsInstallationSettings[0]?.isActive
                ? linkingCenter
                : []),
              ...(readStorefront || manageStorefront ? catalogManagement : []),
            ],
          },
        ],
        [...memoInformation]
      )
    : [];

  const externalUserCanSeeShortcuts = true;
  const externalUserCanSeePracticeSettings = hasAnyPermissionOnList([
    Permission.READ_STOREFRONT,
    Permission.MANAGE_STOREFRONT,
    Permission.READ_PIMS_SETTINGS,
    Permission.MODIFY_PIMS_SETTINGS,
    Permission.MODIFY_PRACTICE_DETAILS,
    Permission.MANAGE_PRACTICE_DETAILS,
    Permission.MODIFY_PRACTICE_LOGO,
    Permission.MODIFY_PRACTICE_SUBDOMAIN,
    Permission.MANAGE_BANK_ACCOUNT_INFO,
  ]);
  const externalUserCanSeeUsers = userManagement;

  const externalUserCanSeeCatalogManagement =
    managePracticeProductCatalogPricing;

  const externalUserCanSeeSettingsSection =
    externalUserCanSeeShortcuts ||
    externalUserCanSeePracticeSettings ||
    externalUserCanSeeUsers ||
    externalUserCanSeeCatalogManagement;

  const externalUserMenuItems: Array<MenuItem> = useMemo(
    () => [
      ...(!isMobile
        ? [
            {
              menuItem: (
                <Link to='/'>
                  {intl.formatMessage({ defaultMessage: 'Home' })}
                </Link>
              ),
              icon: (
                <Link to='/'>
                  <LeftNavIcon icon={DashboardIcon} />
                </Link>
              ),
              initiallySelected: pathname === '/',
            },
          ]
        : []),
      createRx,
      clientRequests,
      proscriptRenewals,
      ...(manageDrafts || readDrafts
        ? [
            {
              menuItem: <ManageDraftsMenuItem />,
              icon: acbKey ? (
                <Link to={`/manage-drafts/${acbKey}`}>
                  <LeftNavIcon icon={Icons.PencilIcon} />
                </Link>
              ) : (
                <Link to='/manage-drafts'>
                  <LeftNavIcon icon={Icons.PencilIcon} />
                </Link>
              ),
              initiallySelected: pathname.includes('manage-drafts'),
            },
          ]
        : []),
      {
        menuItem: (
          <Link to='/client-list'>
            {intl.formatMessage({ defaultMessage: 'Client List' })}
          </Link>
        ),
        icon: (
          <Link to='/client-list'>
            <LeftNavIcon icon={Icons.UsersIcon} />
          </Link>
        ),
        initiallySelected: pathname.includes('client-list'),
      },
      ...(canSeeLinkingCenter ? linkingCenter : []),
      ...(manageStorefront || readStorefront || readPracticePricing
        ? [
            {
              menuItem: (
                <Link to='/product-catalog'>
                  {intl.formatMessage({ defaultMessage: 'Product Catalog' })}
                </Link>
              ),
              icon: (
                <Link to='/product-catalog'>
                  <LeftNavIcon icon={Icons.CatalogIcon} />
                </Link>
              ),
              initiallySelected: pathname.includes('product-catalog'),
            },
          ]
        : []),
      ...(readPractice
        ? [
            {
              menuItem: (
                <ResemblesLinkStyled type='button'>
                  {intl.formatMessage({ defaultMessage: 'History' })}
                </ResemblesLinkStyled>
              ),
              icon: <LeftNavIcon icon={Icons.HistoryIcon} />,
              children: [
                {
                  icon: <></>,
                  initiallySelected: pathname.includes('rx-history'),
                  menuItem: (
                    <Link key='0' to='/rx-history'>
                      {intl.formatMessage({ defaultMessage: 'Rx History' })}
                    </Link>
                  ),
                },
                {
                  icon: <></>,
                  initiallySelected: pathname.includes('order-history'),
                  menuItem: (
                    <Link key='1' to='/order-history'>
                      {intl.formatMessage({ defaultMessage: 'Order History' })}
                    </Link>
                  ),
                },
              ],
            },
          ]
        : []),
      ...(readReportsAggregate || readReportsFinancial || readReportsKPI
        ? [
            {
              menuItem: (
                <ResemblesLinkStyled>
                  {intl.formatMessage({ defaultMessage: 'Reporting' })}
                </ResemblesLinkStyled>
              ),
              icon: <LeftNavIcon icon={Icons.ReportingIcon} />,
              children: [
                {
                  icon: <></>,
                  initiallySelected: pathname.includes('reporting'),
                  menuItem: (
                    <Link key='monthly-reports' to='/reporting'>
                      {intl.formatMessage({
                        defaultMessage: 'Monthly Reports',
                      })}
                    </Link>
                  ),
                },
                ...(readReportsKPI
                  ? [
                      {
                        icon: <></>,
                        initiallySelected: pathname.includes('custom-reports'),
                        menuItem: (
                          <Link key='custom-reports' to='/custom-reports'>
                            {intl.formatMessage({
                              defaultMessage: 'Custom Reports',
                            })}
                          </Link>
                        ),
                      },
                    ]
                  : []),
                ...(readReportsAggregate
                  ? [
                      {
                        icon: <></>,
                        initiallySelected: pathname.includes(
                          'analytics-dashboard'
                        ),
                        menuItem: (
                          <Link
                            key='analytics-dashboard'
                            to='/analytics-dashboard'
                          >
                            {intl.formatMessage({
                              defaultMessage: 'Analytics Dashboard',
                            })}
                          </Link>
                        ),
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(externalUserCanSeeSettingsSection
        ? [
            {
              menuItem: (
                <ResemblesLinkStyled type='button'>
                  {intl.formatMessage({ defaultMessage: 'Practice Settings' })}
                </ResemblesLinkStyled>
              ),
              icon: <LeftNavIcon icon={Icons.SettingsIcon} />,
              children: [
                {
                  icon: <></>,
                  initiallySelected: pathname.includes('manage-shortcuts'),
                  menuItem: (
                    <Link key='0' to='/manage-shortcuts'>
                      {intl.formatMessage({
                        defaultMessage: 'Manage Shortcuts',
                      })}
                    </Link>
                  ),
                },
                ...(externalUserCanSeePracticeSettings
                  ? [
                      {
                        icon: <></>,
                        initiallySelected:
                          pathname.includes('practice-settings'),
                        menuItem: (
                          <Link key='1' to='/practice-settings'>
                            {intl.formatMessage({
                              defaultMessage: 'Practice Details',
                            })}
                          </Link>
                        ),
                      },
                    ]
                  : []),
                ...(externalUserCanSeeUsers
                  ? [
                      {
                        icon: <></>,
                        initiallySelected: pathname.includes('users'),
                        menuItem: (
                          <Link key='2' to='/users'>
                            {intl.formatMessage({
                              defaultMessage: 'User Management',
                            })}
                          </Link>
                        ),
                      },
                    ]
                  : []),
                ...(externalUserCanSeeCatalogManagement
                  ? [
                      {
                        icon: <></>,
                        initiallySelected:
                          pathname.includes('catalog-management'),
                        menuItem: (
                          <Link key='3' to='/catalog-management'>
                            {intl.formatMessage({
                              defaultMessage: 'Catalog Management',
                            })}
                          </Link>
                        ),
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      {
        menuItem: (
          <Link
            to={storefrontUrlUpdated}
            key='0'
            target='_blank'
            rel='noreferrer'
          >
            {intl.formatMessage({ defaultMessage: 'Client' })}
            <br />
            {intl.formatMessage({ defaultMessage: 'Storefront' })}
          </Link>
        ),
        icon: (
          <Link
            to={storefrontUrlUpdated}
            key='0'
            target='_blank'
            rel='noreferrer'
          >
            <LeftNavIcon icon={StorefrontLinkIcon} />
          </Link>
        ),
        externalRedirect: true,
        borderTop: true,
      },
    ],
    [...memoInformation]
  );

  if (practicePlatformAccountsError) throw practicePlatformAccountsError;
  if (dataConnectivityError) throw dataConnectivityError;
  if (usePermissionsError) throw usePermissionsError;
  if (getMeError) throw getMeError;
  if (errorCounter) console.error(errorCounter);

  const loading =
    usePermissionsLoading ||
    practicePlatformAccountsLoading ||
    getMeLoading ||
    dataConnectivityLoading;

  const internalUserMenuItemsToDisplay =
    acbKey || params.acbKey
      ? internalUserMenuItems
      : internalTopLevelPracticeSearchMenu;
  const userMenuItems = internalUser
    ? internalUserMenuItemsToDisplay.concat(internalTopLevelPartnerApiKeyMenu)
    : externalUserMenuItems;

  return (
    <div>
      <ContainerLoader active={loading} />
      <LeftNav
        openNavigationAriaLabel={intl.formatMessage({
          defaultMessage: 'Toggle Left Navigation',
        })}
        onOpen={(open: boolean) => dispatch(LeftNavActions.setIsOpen(open))}
        className='left-navigation-irx'
        leftPosition='0'
        topPosition={`${topPosition}px`}
        isMobile={isMobile}
        menuItems={userMenuItems}
        isOpen={leftNavOpen}
        style={
          isMobile
            ? {
                paddingBottom: '100px',
                ...(!leftNavOpen ? { width: 0 } : { width: '240px' }),
                paddingLeft: 0,
                paddingRight: 0,
              }
            : { paddingBottom: '100px' }
        }
      />
    </div>
  );
};

export default LeftNavigation;
