/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface UserProfileState {
  containerLoading: boolean;
  editingFullPrescriberForm: boolean;
  deaLicenseError: boolean;
}

const initialState: UserProfileState = {
  containerLoading: false,
  editingFullPrescriberForm: false,
  deaLicenseError: false,
};

export const UserProfileSlice = createSlice({
  initialState,
  name: 'userProfile',
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.containerLoading = action.payload;
    },
    setEditingFullPrescriberForm(state, action: PayloadAction<boolean>) {
      state.editingFullPrescriberForm = action.payload;
    },
    setDeaLicenseError(state, action: PayloadAction<boolean>) {
      state.deaLicenseError = action.payload;
    },
  },
});

export const { reducer: UserProfile, actions: UserProfileActions } =
  UserProfileSlice;
