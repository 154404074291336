import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import {
  useGetMeLazyQuery,
  useFetchPracticeDetailsLazyQuery,
  useFetchPracticePlatformAccountsLazyQuery,
} from '../api/generated/graphql';
import type { Identifier } from '../api/generated/graphql';
import usePracticeSelection from './usePracticeSelection/usePracticeSelection';
import type { RootState } from '../redux/store/Store';
import useAsyncError from './useAsyncError';

interface PendoHook {
  initialize: () => void;
  accountChange: () => void;
}

const usePendo = (): PendoHook => {
  const { isAuthenticated, user } = useAuth0();
  const { currentPractice, loading: loadingContext } = usePracticeSelection();
  const throwError = useAsyncError();
  const [getMe] = useGetMeLazyQuery({
    onError: (error) => {
      console.error('Error getting me in usePendo', error);
      throwError(error);
    },
  });
  const [getPlatformAccounts] = useFetchPracticePlatformAccountsLazyQuery();
  const [getPracticeDetails] = useFetchPracticeDetailsLazyQuery();
  const { getIdTokenClaims } = useAuth0();

  const internalUser = useSelector(
    (state: RootState) => state.UserStatus.isInternal
  );

  const [initialized, setInitialized] = useState<boolean>(false);

  const makePendoObject = async () => {
    const claims = await getIdTokenClaims();
    const me = await getMe();
    const meData = me.data?.getMe;

    const currentPracticeKey = currentPractice.value;

    const platformAccountsResponse = currentPracticeKey
      ? await getPlatformAccounts({
          variables: {
            practicePlatformAccountsInput: {
              animalCareBusinessKey: currentPracticeKey ?? '',
            },
          },
        })
      : null;
    const platformAccountKey =
      platformAccountsResponse?.data?.getPracticePlatformAccounts[0]
        .platformAccountKey;

    const practiceResponse = platformAccountKey
      ? await getPracticeDetails({
          variables: {
            platformAccountInput: {
              platformAccountKey: platformAccountKey ?? '',
              animalCareBusinessKey: currentPracticeKey ?? '',
            },
          },
        })
      : null;

    const practiceDetails = practiceResponse?.data?.readPlatformAccount;

    const dvmId = practiceDetails?.identifier?.find(
      (i: Identifier) => i.identifierTypeCode === 'DVM_ID'
    )?.identifierId;

    const visitorId = internalUser
      ? `internal-${meData?.userKey}`
      : meData?.userKey;
    const accountId = internalUser
      ? `internal-${currentPracticeKey}`
      : currentPracticeKey;

    return {
      visitor: {
        id: visitorId,
        rxpro360_userKey: meData?.userKey,
        rxpro360_creationDate: meData?.createdDate,
        rxpro360_createdByUserKey: meData?.createdByUserKey,
        ...(meData?.modifiedDate
          ? { rxpro360_modifiedDate: meData?.modifiedDate }
          : {}),
        ...(meData?.modifiedByUserKey
          ? { rxpro360_modifiedByUserKey: meData?.modifiedByUserKey }
          : {}),
        rxpro360_givenName: meData?.givenName,
        rxpro360_familyName: meData?.familyName,
        rxpro360_email: meData?.email,
        rxpro360_roles: meData?.roles,
        rxpro360_isActive: meData?.active,
        rxpro360_jobTitle: meData?.jobTitle,
        ...(practiceDetails?.corporateGroupId
          ? { rxpro360_corporateGroupId: practiceDetails?.corporateGroupId }
          : {}),
        ...(practiceDetails?.corporateGroupName
          ? { rxpro360_corporateGroupName: practiceDetails?.corporateGroupName }
          : {}),
        ...(claims ? { SSO_id: claims['https://covetrus/identityid'] } : {}),
        ...(claims ? { SSO_email: claims['https://covetrus/email'] } : {}),
      },
      account: {
        id: accountId,
        ...(currentPracticeKey ? { rxpro360_acbKey: currentPracticeKey } : {}),
        ...(dvmId ? { rxpro360_accountid: dvmId } : {}),
        ...(practiceDetails?.storefrontDateActivated
          ? {
              rxpro360_launchdate: practiceDetails?.storefrontDateActivated,
            }
          : {}),
        ...(practiceDetails?.practiceTypeCode
          ? {
              rxpro360_practicetype: practiceDetails?.practiceTypeCode,
            }
          : {}),
        ...(practiceDetails?.marketFocusTypeCode
          ? {
              rxpro360_marketfocus: practiceDetails?.marketFocusTypeCode,
            }
          : {}),
        ...(practiceDetails?.primaryAccountCovetrusCustomerNumber
          ? {
              CVET_id: practiceDetails?.primaryAccountCovetrusCustomerNumber,
            }
          : {}),
      },
    };
  };

  const initialize = async () => {
    const obj = await makePendoObject();
    // eslint-disable-next-line
    (window as any).pendo.initialize(obj);
    setInitialized(true);
  };

  const accountChange = async () => {
    const obj = await makePendoObject();
    // eslint-disable-next-line
    (window as any).pendo.identify(obj);
  };

  useEffect(() => {
    // We are checking internalUser !== null since it means context has loaded
    if (
      isAuthenticated &&
      !initialized &&
      !loadingContext &&
      internalUser !== null &&
      user
    ) {
      initialize();
    }
  }, [isAuthenticated, loadingContext, internalUser]);

  useEffect(() => {
    if (isAuthenticated && initialized) {
      accountChange();
    }
  }, [currentPractice.value, initialized]);

  return {
    initialize,
    accountChange,
  };
};
export default usePendo;
