import type { ToastProps } from '@covetrus/design-system-library';
import { ToastType } from '@covetrus/design-system-library';
import type { RxValidationErrorCode } from '@covetrus/reference-data';
import { RxValidationErrorCodeMessages } from '@covetrus/reference-data';
import { nanoid } from 'nanoid';
import type { IntlShape } from 'react-intl';
import reactiveToastState from './toaster.state';

const toastCloser = (id: string) => {
  reactiveToastState(
    reactiveToastState().filter(({ id: oldId }) => id !== oldId)
  );
};

const toastMaker = (closer: (id: string) => void) => (props: ToastProps) => {
  const id = props?.id ?? nanoid();

  const fullProps: ToastProps = {
    ...props,
    id,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      closer(id);
    },
  };

  return fullProps;
};

const makeToast = toastMaker(toastCloser);

// Generic Toast Adder
export const addToast = (props: ToastProps) => {
  reactiveToastState([...reactiveToastState(), makeToast(props)]);
};

// Utility to help the UI manage multiple (too many) toasts at once
export const anyToast = () => reactiveToastState().length;

// Toast adder for Errors with correct defaults
export const addErrorToast = (props: ToastProps) => {
  const errorToast = makeToast({
    ...props,
    type: ToastType.DANGER,
    timed: false,
  });
  reactiveToastState([...reactiveToastState(), errorToast]);
};

export const addGraphQlSubmissionErrorToast = (
  intl: IntlShape,
  type: string,
  code: RxValidationErrorCode,
  message: string
) => {
  if (type && type === 'VALIDATION_ERROR' && code) {
    addToast({
      type: ToastType.DANGER,
      content:
        RxValidationErrorCodeMessages[code]?.defaultMessage ??
        message ??
        intl.formatMessage({ defaultMessage: 'Validation Error' }),
    });
  } else {
    addStandardSubmissionErrorToast(intl);
  }
};

export const addStandardSubmissionErrorToast = (intl: IntlShape) => {
  addToast({
    type: ToastType.DANGER,
    content: intl.formatMessage({
      defaultMessage: 'Submission failed, please retry or contact support',
    }),
  });
};

export const addStandardDeletionErrorToast = (intl: IntlShape) => {
  addToast({
    type: ToastType.DANGER,
    content: intl.formatMessage({
      defaultMessage: 'Deletion failed, please retry or contact support',
    }),
  });
};

export const uploadLogoImageSuccessToast = (intl: IntlShape) => {
  addToast({
    type: ToastType.SUCCESS,
    content: intl.formatMessage({
      defaultMessage: 'Logo has been successfully uploded',
    }),
  });
};

export const uploadArtifactSuccessToast = (intl: IntlShape) => {
  addToast({
    type: ToastType.SUCCESS,
    content: intl.formatMessage({
      defaultMessage: 'Artifact has been successfully uploded',
    }),
  });
};
