import styled from 'styled-components';
import { theme } from '@covetrus/design-system-library';
import type { DraftMenuItemProps } from '../types/DraftMenuItemProps';

const DraftMenuItem = styled.span.attrs<DraftMenuItemProps>(
  () => ({})
)<DraftMenuItemProps>`
  display: flex;
  align-items: center;
  font-weight: normal;
  color: ${(props) => (props.$loading ? theme.color.neutral300 : 'inherit')};
`;
export default DraftMenuItem;
