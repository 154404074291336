/* eslint-disable irx/pascal-filenames */
import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import type { StyledTarget } from 'styled-components/dist/types';
import { StyleSheetManager } from 'styled-components';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Auth0ProviderWithNavigate from './Auth0ProviderWithNavigate';
import NavigatorContextProvider from './contexts/NavigatorContextProvider';
import MainApp from './MainApp';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import GenericError from './components/GenericError/GenericError';
import Store from './redux/store/Store';

const App = () => {
  return <RouterProvider router={router} />;
};
async function bootstrapApplication() {
  const root = createRoot(document.getElementById('root') as HTMLElement);

  // This implements the default behavior from styled-components v5
  function shouldForwardProp(propName: string, target: StyledTarget<'web'>) {
    if (typeof target === 'string') {
      return isPropValid(propName);
    }
    return true;
  }

  root.render(
    <React.StrictMode>
      <div style={{ display: 'none' }}>
        {`Build Hash: ${process.env.BUILD_HASH}`}
      </div>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <App />
      </StyleSheetManager>
    </React.StrictMode>
  );
}

bootstrapApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const Root = () => {
  return (
    <>
      <NavigatorContextProvider>
        <ErrorBoundary FallbackComponent={<GenericError />}>
          <Auth0ProviderWithNavigate>
            <ErrorBoundary FallbackComponent={<GenericError />}>
              <Provider store={Store}>
                <MainApp />
              </Provider>
            </ErrorBoundary>
          </Auth0ProviderWithNavigate>
        </ErrorBoundary>
      </NavigatorContextProvider>
    </>
  );
};
const router = createBrowserRouter([{ path: '*', element: <Root /> }]);
