import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import type { ServerError } from '@apollo/client';
import type { GraphQLFormattedError } from 'graphql';
import type {
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-spa-js';
import {
  reactiveCurrentContext,
  reactiveCurrentPractice,
} from '../hooks/usePracticeSelection/usePracticeSelection';
import { ContextCode } from '../hooks/usePracticeSelection/types';

export const authLink = (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string>,
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>
) =>
  setContext(async () => {
    const accessToken = await getAccessTokenSilently();
    const currentContext = reactiveCurrentContext();
    const currentPractice = reactiveCurrentPractice();

    if (!accessToken) {
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      }).catch((e) => {
        // if anything goes wrong in here, the errors get swallowed if we don't explicitly log
        console.error(`Error refreshing tokens: ${e}`);
        throw new Error(`Error refreshing tokens: ${e}`);
      });
    }

    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...(currentContext?.contextKey && {
          ContextKey: currentContext.contextKey,
        }),
        ...(currentContext?.userContextCode === ContextCode.Corporate &&
          currentPractice.value && {
            PracticeKey: currentPractice.value,
          }),
      },
    };
  });

// eslint-disable-next-line
export const errorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const now = new Date();
    if (networkError) {
      console.error(
        'Network Error: ',
        '\n   Error Message: ',
        networkError.message || networkError,
        '\n   Status Code: ',
        'statusCode' in networkError
          ? (networkError as ServerError).statusCode //eslint-disable-line
          : 'Not available',
        '\n   Request Operation: ',
        operation.operationName,
        '\n   Request Variables: ',
        operation.variables,
        '\n   Timestamp: ',
        now.toString(),
        `errorMessage=${networkError.message || networkError}`,
        `statusCode=${
          'statusCode' in networkError
            ? (networkError as ServerError).statusCode //eslint-disable-line
            : 'Not available'
        }`,
        `requestOperation=${operation.operationName}`,
        `requestVariables=${operation.variables}`,
        `timestamp=${now.toString()}`
      );
    }
    if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.forEach((graphQLError: GraphQLFormattedError) =>
        console.error(
          'GraphQL Error: ',
          '\n   GraphQL Query Path: ',
          graphQLError.path ? graphQLError.path.join(' --> ') : 'N/A',
          '\n   Error Message: ',
          graphQLError.message,
          '\n   Request Operation: ',
          operation.operationName,
          '\n   Request Variables: ',
          operation.variables,
          '\n   Timestamp: ',
          now.toString(),
          `queryPath=${
            graphQLError.path ? graphQLError.path.join(' --> ') : 'N/A'
          }`,
          `errorMessage=${graphQLError.message}`,
          `requestOperation=${operation.operationName}`,
          `requestVariables=${operation.variables}`,
          `timestamp=${now.toString()}`
        )
      );
    }
  });
