import styled from 'styled-components';

const PracticeNameStyled = styled.div`
  display: flex;
  font-weight: bold;
  white-space: normal;
  padding-right: 4px;
`;

export default PracticeNameStyled;
