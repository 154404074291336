import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Locales } from '@covetrus/reference-data';

interface LanguageState {
  locale: Locales;
}

const initialState: LanguageState = {
  locale: (window.navigator.language as Locales) ?? Locales.en_US,
};

const LanguageSlice = createSlice({
  name: 'Language',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<LanguageState>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { reducer: Language, actions: LanguageActions } = LanguageSlice;
