import React, { useState, useEffect } from 'react';
import { Locales } from '@covetrus/reference-data';
import { useAuth0 } from '@auth0/auth0-react';
import type { MessageFormatElement } from 'react-intl';
import { createIntl } from 'react-intl';
import {
  PageError,
  GenericSpinner,
  ErrorTypes,
  Button,
} from '@covetrus/design-system-library';
import { useSelector } from 'react-redux';
import loadLocaleData from '../../intl/loadIntl';
import Locale from '../../intl/types/Locale.types';
import type { RootState } from '../../redux/store/Store';
import { internalLogoutPath } from '../../constants/Constants';

const NotAuthorized: React.FC = () => {
  const [messages, setMessages] = useState<
    Record<string, Array<MessageFormatElement>>
  >({});

  useEffect(() => {
    let isSubscribed = true;
    loadLocaleData(Locales.en_US)
      .then((result) => isSubscribed && setMessages(result.default))
      .catch((error) => console.error(error));
    return () => {
      isSubscribed = false;
    };
  }, []);

  if (Object.values(messages).length) return <Loaded messages={messages} />;

  return <GenericSpinner />;
};

const Loaded: React.FC<{
  messages: Record<string, Array<MessageFormatElement>>;
}> = ({
  messages,
}: {
  messages: Record<string, Array<MessageFormatElement>>;
}) => {
  const intl = createIntl({ messages, locale: Locale['en-US'] });
  const { logout } = useAuth0();
  const internalUser = useSelector(
    (state: RootState) => state.UserStatus.isInternal
  );
  const doLogout = () => {
    if (internalUser) {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}${internalLogoutPath}`,
        },
      });
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };
  return (
    <PageError
      headerText={intl.formatMessage({
        defaultMessage: 'Authorization Required',
      })}
      errorType={ErrorTypes.ERROR_401}
      actionButton={
        <Button onClick={() => doLogout()}>
          {intl.formatMessage({ defaultMessage: 'Logout' })}
        </Button>
      }
      subHeaderText={
        <span>
          {intl.formatMessage({
            defaultMessage: 'You do not have permission to view this page.',
          })}
          {'\n'}
          {'\n'}
          <span
            style={{
              display: 'inline-block',
              marginLeft: '30px',
              marginRight: '30px',
              boxSizing: 'border-box',
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Please Contact your system administrator.',
            })}
          </span>
        </span>
      }
    />
  );
};

export default NotAuthorized;
