export interface Part {
  contentDispositionHeader: string;
  contentTypeHeader: string;
  part: Array<number>;
}

interface Input {
  filename?: string;
  name?: string;
  type: string;
  data: Buffer;
}

export const OCTET_STREAM = 'application/octet-stream';

export const APPLICATION_ZIP = 'application/zip';

export const ZIP_EXTENSION = '.zip';

export const APPLICATION_JSON = 'application/json';

enum ParsingState {
  INIT,
  READING_HEADERS,
  READING_DATA,
  READING_PART_SEPARATOR,
}

export function getMultiparts(
  multipartBodyBuffer: Buffer,
  boundary: string
): Array<Input> {
  let lastline = '';
  let contentDispositionHeader = '';
  let contentTypeHeader = '';
  let state: ParsingState = ParsingState.INIT;
  let buffer: Array<number> = [];
  const allParts: Array<Input> = [];

  let currentPartHeaders: Array<string> = [];

  for (let i = 0; i < multipartBodyBuffer.length; i++) {
    const oneByte: number = multipartBodyBuffer[i];
    const prevByte: number | null = i > 0 ? multipartBodyBuffer[i - 1] : null;
    // 0x0a is the same as \n
    // 0x0d is the same as  \r
    const newLineDetected: boolean = oneByte === 0x0a && prevByte === 0x0d;
    const newLineChar: boolean = oneByte === 0x0a || oneByte === 0x0d;

    if (!newLineChar) lastline += String.fromCharCode(oneByte);
    if (ParsingState.INIT === state && newLineDetected) {
      // searching for boundary
      if (`--${boundary}` === lastline) {
        state = ParsingState.READING_HEADERS; // found boundary. start reading headers
      }
      lastline = '';
    } else if (ParsingState.READING_HEADERS === state && newLineDetected) {
      // parsing headers. Headers are separated by an empty line from the content. Stop reading headers when the line is empty
      if (lastline.length) {
        currentPartHeaders.push(lastline);
      } else {
        // found empty line. search for the headers we want and set the values
        for (const header of currentPartHeaders) {
          if (header.toLowerCase().startsWith('content-disposition:')) {
            contentDispositionHeader = header;
          } else if (header.toLowerCase().startsWith('content-type:')) {
            contentTypeHeader = header;
          }
        }
        state = ParsingState.READING_DATA;
        buffer = [];
      }
      lastline = '';
    } else if (ParsingState.READING_DATA === state) {
      // parsing data
      if (lastline.length > boundary.length + 4) {
        lastline = ''; // mem save
      }
      if (`--${boundary}` === lastline) {
        // Reset buffer if lastline triggered
        const j = buffer.length - lastline.length;
        const part = buffer.slice(0, j - 1);

        allParts.push(
          process({ contentDispositionHeader, contentTypeHeader, part })
        );
        buffer = [];
        currentPartHeaders = [];
        lastline = '';
        state = ParsingState.READING_PART_SEPARATOR;
        contentDispositionHeader = '';
        contentTypeHeader = '';
      } else {
        buffer.push(oneByte);
      }
      if (newLineDetected) {
        lastline = '';
      }
    } else if (ParsingState.READING_PART_SEPARATOR === state) {
      if (newLineDetected) {
        state = ParsingState.READING_HEADERS;
      }
    }
  }
  return allParts;
}

export const getBoundary = (header: string): string => {
  const items = header.split(';');
  if (items) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i].trim();
      if (item.indexOf('boundary') >= 0) {
        const k = item.split('=');
        return k[1].trim().replace(/^["']|["']$/g, '');
      }
    }
  }
  return '';
};

function process(part: Part): Input {
  const getFileNameAndGenerateInput = (str: string) => {
    const contentDispositionKeys = str.split('=');
    const filenameKey = contentDispositionKeys[0].trim();
    const filenameValue = JSON.parse(contentDispositionKeys[1].trim());
    const input: Partial<Input> = {};
    if (filenameKey === 'filename') {
      input.filename = filenameValue;
    }
    return input;
  };
  const header = part.contentDispositionHeader.split(';');
  // filename will always be 3rd.
  const filenameData = header[2];
  let input: Partial<Input> = {};

  if (filenameData) {
    input = getFileNameAndGenerateInput(filenameData);
  }

  input.type = part.contentTypeHeader?.split(':')?.[1]?.trim();
  // always process the name field

  const name = header[1].split('=')[1].replace(/"/g, '');

  input.name = name;

  input.data = Buffer.from(part.part);

  return input as Input;
}
