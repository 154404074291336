import { makeVar } from '@apollo/client';
import type { ReactiveVar } from '@apollo/client';

export enum InternalStatusKey {
  INTERNAL_STATUS_KEY = 'INTERNAL_STATUS_KEY',
}

export enum InternalUserStatus {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export const initialStatus = (localStorage.getItem(
  InternalStatusKey.INTERNAL_STATUS_KEY
) || InternalUserStatus.LOGGED_IN) as InternalUserStatus;

export const internalStatusReactiveVar: ReactiveVar<InternalUserStatus> =
  makeVar(initialStatus);

export const updateInternalLoginStatus = (status: InternalUserStatus) => {
  internalStatusReactiveVar(status);
  localStorage.setItem(InternalStatusKey.INTERNAL_STATUS_KEY, status);
};
