import React from 'react';
import type { LeftNavIconProps } from '../types/LeftNavIconProps';

const LeftNavIcon: React.FC<LeftNavIconProps> = ({ icon }) => {
  const Icon = icon;

  return <Icon width='24' height='24' />;
};

export default LeftNavIcon;
