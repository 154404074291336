import type { IConfig } from '../interfaces/IConfig';

const config: IConfig = {
  graphQLUri: 'http://localhost:4000/graphql',
  contexts: 'http://localhost:4000/contexts',
  exports: 'http://localhost:4000/exports',

  auth0Domain: 'covetrus-int.auth0.com',
  auth0ClientId: 'A16KVGxsa0MjHAx4sxo8zxcgjPPxTtqi',
  imageUpload: 'http://localhost:4000/platform-account',
  practice: 'http://localhost:4000/practice',
  artifactUpload: 'http://localhost:4000/artifacts',
  reportDownload: 'http://localhost:4000/reports/prescribing',
  defaultProductImage:
    'https://d181rm1bh0chxr.cloudfront.net/image/ac3fc591-a431-4cfb-8e4a-bcad09969a24.png',
  errors: 'http://localhost:4000/errors',
};

export default config;
