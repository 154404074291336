/* eslint-disable irx/pascal-filenames */
import React from 'react';
import { Route } from 'react-router-dom';
import type { ReactElement } from 'react';
import { Permission } from '@covetrus/reference-data';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import lazyWithRefresh from '../utils/lazyWithRefresh';

const clientProfileImport = () =>
  import('../domains/Client/ClientProfile/ClientProfileContainer');
const patientProfileImport = () =>
  import('../domains/Patient/PatientProfile/PatientProfileContainer');
const clientListImport = () =>
  import('../domains/Client/ClientList/ClientList');
const userProfileImport = () =>
  import('../domains/Users/UserProfile/UserProfileResolver');
const userManagementImport = () =>
  import('../domains/Users/UserManagement/UserManagement');
const corporateLandingPageImport = () =>
  import('../domains/LandingPage/CorporateLandingPage');
const myAccountImport = () => import('../domains/MyAccount/MyAccount');
const manageShortcutsImport = () =>
  import('../domains/ManageShortcuts/ManageShortcuts');
const manageDraftsImport = () => import('../domains/ManageDrafts/ManageDrafts');
const createRxImport = () => import('../domains/Patient/CreateRx/CreateRx');
const LinkingPatientListImport = () =>
  import('../domains/LinkingCenter/Patient/LinkingPatientList');
const RxHistoryImport = () => import('../domains/RxHistory/RxHistory');
const ClientRequestsImport = () =>
  import('../domains/ClientRequests/ClientRequests');
const OrderHistoryImport = () => import('../domains/OrderHistory/OrderHistory');
const RxDetailsImport = () => import('../domains/RxDetails/RxDetailsContainer');
const ApproveClientRequestImport = () =>
  import('../domains/ApproveClientRequest/ApproveClientRequest');
const OrderDetailsImport = () => import('../domains/OrderDetails/OrderDetails');
const PracticeSettingsImport = () =>
  import('../domains/ExternalUserPracticeSettings/PracticeSettings');
const ProScriptRenewalsImport = () =>
  import('../domains/ProScriptRenewals/ProScriptRenewals');
const ProductCatalogImport = () =>
  import('../domains/ProductCatalog/ProductCatalog');
const ReportingImport = () => import('../domains/Reporting/Reporting');
const CatalogManagementImport = () =>
  import('../domains/CatalogManagement/CatalogManagementContainer');
const CustomReportingImport = () =>
  import('../domains/CustomReporting/CustomReporting');
const AnalyticsImport = () =>
  import('../domains/AnalyticsDashboard/AnalyticsDashboard');

const ClientProfile = lazyWithRefresh(clientProfileImport);
const PatientProfile = lazyWithRefresh(patientProfileImport);
const ClientList = lazyWithRefresh(clientListImport);
const UserProfile = lazyWithRefresh(userProfileImport);
const UserManagement = lazyWithRefresh(userManagementImport);
const CorporateLandingPage = lazyWithRefresh(corporateLandingPageImport);
const MyAccount = lazyWithRefresh(myAccountImport);
const ManageShortcuts = lazyWithRefresh(manageShortcutsImport);
const ManageDrafts = lazyWithRefresh(manageDraftsImport);
const CreateRx = lazyWithRefresh(createRxImport);
const LinkingPatientList = lazyWithRefresh(LinkingPatientListImport);
const RxHistory = lazyWithRefresh(RxHistoryImport);
const ClientRequests = lazyWithRefresh(ClientRequestsImport);
const OrderHistory = lazyWithRefresh(OrderHistoryImport);
const RxDetails = lazyWithRefresh(RxDetailsImport);
const ApproveClientRequest = lazyWithRefresh(ApproveClientRequestImport);
const OrderDetails = lazyWithRefresh(OrderDetailsImport);
const PracticeSettings = lazyWithRefresh(PracticeSettingsImport);
const ProScriptRenewals = lazyWithRefresh(ProScriptRenewalsImport);
const ProductCatalog = lazyWithRefresh(ProductCatalogImport);
const Reporting = lazyWithRefresh(ReportingImport);
const CatalogManagement = lazyWithRefresh(CatalogManagementImport);
const CustomReporting = lazyWithRefresh(CustomReportingImport);
const Analytics = lazyWithRefresh(AnalyticsImport);

const corporateUserPaths = (): ReactElement | null => (
  <>
    <Route path='/' element={<CorporateLandingPage />} />
    <Route path='/client/:clientKey' element={<ClientProfile />} />
    <Route path='/patient/:patientKey' element={<PatientProfile />} />
    <Route path='/create-rx' element={<CreateRx />} />
    <Route path='/create-rx/:step' element={<CreateRx />} />
    <Route path='/client-list' element={<ClientList />} />
    <Route path='/my-account' element={<MyAccount />} />
    <Route
      path='/user/:userKey'
      element={
        <ProtectedRoute
          allowedPermissions={[
            Permission.MANAGE_PRACTICE_USER,
            Permission.READ_PRACTICE_USER,
          ]}
        >
          <UserProfile />
        </ProtectedRoute>
      }
    />
    <Route
      path='/users'
      element={
        <ProtectedRoute
          allowedPermissions={[
            Permission.READ_PRACTICE_USER,
            Permission.MANAGE_PRACTICE_USER,
          ]}
        >
          <UserManagement />
        </ProtectedRoute>
      }
    />
    <Route path='/manage-shortcuts' element={<ManageShortcuts />} />
    <Route path='/manage-drafts' element={<ManageDrafts />} />
    <Route path='/linking/patient/list' element={<LinkingPatientList />} />
    <Route path='/rx-history' element={<RxHistory />} />
    <Route path='/client-requests' element={<ClientRequests />} />
    <Route path='/order-history' element={<OrderHistory />} />
    <Route path='/proscript-renewals' element={<ProScriptRenewals />} />
    <Route
      path='/approve-client-request/:approvalKey'
      element={<ApproveClientRequest />}
    />
    <Route
      path='/rx-details/:prescriptionTypeCode/:prescriptionKey'
      element={<RxDetails />}
    />
    <Route path='/rx-details/approval/:approvalKey' element={<RxDetails />} />
    <Route path='/order-details/:orderNumber' element={<OrderDetails />} />
    <Route
      path='/practice-settings'
      element={
        <ProtectedRoute
          allowedPermissions={[
            Permission.READ_STOREFRONT,
            Permission.MANAGE_STOREFRONT,
            Permission.READ_PIMS_SETTINGS,
            Permission.MODIFY_PIMS_SETTINGS,
            Permission.MODIFY_PRACTICE_DETAILS,
            Permission.MANAGE_PRACTICE_DETAILS,
            Permission.MODIFY_PRACTICE_LOGO,
            Permission.MODIFY_PRACTICE_SUBDOMAIN,
            Permission.MANAGE_BANK_ACCOUNT_INFO,
          ]}
        >
          <PracticeSettings />
        </ProtectedRoute>
      }
    />
    <Route path='/product-catalog' element={<ProductCatalog />} />
    <Route
      path='/reporting'
      element={
        <ProtectedRoute
          allowedPermissions={[
            Permission.READ_REPORTS_AGGREGATE,
            Permission.READ_REPORTS_FINANCIAL,
            Permission.READ_REPORTS_KPI,
          ]}
        >
          <Reporting />
        </ProtectedRoute>
      }
    />
    <Route
      path='/catalog-management'
      element={
        <ProtectedRoute
          allowedPermissions={[
            Permission.MANAGE_PRACTICE_PRODUCT_CATALOG_PRICING,
          ]}
        >
          <CatalogManagement />
        </ProtectedRoute>
      }
    />
    <Route
      path='/custom-reports'
      element={
        <ProtectedRoute allowedPermissions={[Permission.READ_REPORTS_KPI]}>
          <CustomReporting />
        </ProtectedRoute>
      }
    />
    <Route
      path='/analytics-dashboard'
      element={
        <ProtectedRoute
          allowedPermissions={[Permission.READ_REPORTS_AGGREGATE]}
        >
          <Analytics />
        </ProtectedRoute>
      }
    />
  </>
);

export default corporateUserPaths;
