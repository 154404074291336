import styled from 'styled-components';
import { theme } from '@covetrus/design-system-library';

const CountStyled = styled.div`
  margin-left: 6px;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
  background-color: ${theme.color.brandTertiary500};
  border-radius: 50%;
  font-weight: normal;
  color: ${theme.color.neutralWhite} !important;
  font-size: ${theme.fontSize.sizeFontXs};
`;

export default CountStyled;
