import React, { useEffect } from 'react';
import {
  InternalUserStatus,
  updateInternalLoginStatus,
} from '../../State/InternalUserAuthStatus.state';

const HandleLogout: React.FC = () => {
  useEffect(() => {
    updateInternalLoginStatus(InternalUserStatus.LOGGED_OUT);
  }, []);
  return <div />;
};

export default HandleLogout;
