/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { theme } from '@covetrus/design-system-library';

export interface LeftNavigationState {
  isOpen: boolean;
}

const initialState: LeftNavigationState = {
  isOpen: !window.matchMedia(`(max-width: ${theme.displaySizes.smartphones})`)
    .matches,
};

export const LeftNavSlice = createSlice({
  initialState,
  name: 'leftNav',
  reducers: {
    setIsOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { reducer: LeftNav, actions: LeftNavActions } = LeftNavSlice;
