import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import DraftMenuItem from '../styles/DraftsMenuItem';
import { useGetDraftListQuery } from '../../../api/generated/graphql';
import CountStyled from './CountStyled';
import type { ManageDraftsMenuItemProps } from '../types/ManageDraftsMenuItemProps';
import usePracticeSelection from '../../../hooks/usePracticeSelection/usePracticeSelection';

const ManageDraftsMenuItem: React.FC<ManageDraftsMenuItemProps> = ({
  acbKey,
}) => {
  const intl = useIntl();
  const { currentPractice } = usePracticeSelection();
  const practiceKey = acbKey || currentPractice.value;

  const {
    data: dataDraftList,
    loading: loadingDraftList,
    error: errorDraftList,
  } = useGetDraftListQuery({
    variables: {
      getDraftInput: {
        ...(practiceKey ? { acbKey: practiceKey } : {}),
      },
    },
  });

  const activeDrafts =
    !loadingDraftList && dataDraftList
      ? dataDraftList?.getDraftList.drafts.length > 0
      : false;

  const draftCount =
    !loadingDraftList && dataDraftList
      ? dataDraftList?.getDraftList.drafts.length
      : '';

  if (errorDraftList) {
    console.error('Error loading draft list.');
  }

  const linkTo = acbKey ? `/manage-drafts/${acbKey}` : '/manage-drafts';

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <Link to={linkTo}>
        <DraftMenuItem
          $loading={loadingDraftList}
          activeDrafts={errorDraftList ? false : activeDrafts}
        >
          {intl.formatMessage({ defaultMessage: 'Manage Drafts' })}
        </DraftMenuItem>
      </Link>
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
        {!errorDraftList && draftCount ? (
          <CountStyled>{draftCount > 99 ? '99+' : draftCount}</CountStyled>
        ) : null}
      </div>
    </div>
  );
};

export default ManageDraftsMenuItem;
