import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line max-len
import AddOrEditShortcutModes from '../../domains/ManageShortcuts/AddOrEditShortcut/types/AddOrEditShortcutModes';
import type {
  SearchedShortcutFragment,
  ShortcutTypeCode,
} from '../../api/generated/graphql';

export interface AddShortcutStarter {
  initialShortcutTypeCode: ShortcutTypeCode;
  initialNote: string;
}

export interface EditShortcutFromScriptPadInput {
  note: string;
}

export interface SelectedShortcut {
  shortcutKey: string;
  label: string;
  note: string;
  shortcutTypeCode: ShortcutTypeCode;
  practiceKey: string;
}

export interface ManageShortcutsState {
  addOrEditShortcutMode: AddOrEditShortcutModes;
  shortcutBeingEdited: SearchedShortcutFragment | null;
  showAddOrEditShortcut: boolean;
  initialForAddShortcut: AddShortcutStarter | null;
  shortcutAdded: SearchedShortcutFragment | null;
  otcScriptPadSelectedShortcut: SelectedShortcut | null;
  currentShortcutNoteTextBeingEdited: string;
}

const initialState: ManageShortcutsState = {
  addOrEditShortcutMode: AddOrEditShortcutModes.CREATE,
  shortcutBeingEdited: null,
  showAddOrEditShortcut: false,
  initialForAddShortcut: null,
  shortcutAdded: null,
  otcScriptPadSelectedShortcut: null,
  currentShortcutNoteTextBeingEdited: '',
};

export const ManageShortcutsSlice = createSlice({
  initialState,
  name: 'manageShortcuts',
  reducers: {
    closeShortcutSlider(state) {
      return {
        ...initialState,
        otcScriptPadSelectedShortcut: state.otcScriptPadSelectedShortcut,
      };
    },
    createShortcut(state) {
      return {
        ...state,
        showAddOrEditShortcut: true,
        addOrEditShortcutMode: AddOrEditShortcutModes.CREATE,
        shortcutBeingEdited: null,
      };
    },
    createShortcutWithInitialData(
      state,
      action: PayloadAction<AddShortcutStarter>
    ) {
      return {
        ...state,
        showAddOrEditShortcut: true,
        addOrEditShortcutMode: AddOrEditShortcutModes.CREATE,
        shortcutBeingEdited: null,
        initialForAddShortcut: action.payload,
        currentShortcutNoteTextBeingEdited: action.payload.initialNote,
      };
    },
    editShortcut(state, action: PayloadAction<SearchedShortcutFragment>) {
      return {
        ...state,
        addOrEditShortcutMode: AddOrEditShortcutModes.EDIT,
        showAddOrEditShortcut: true,
        shortcutBeingEdited: action.payload,
        currentShortcutNoteTextBeingEdited: action.payload.note,
      };
    },
    shortcutAdded(state, action: PayloadAction<SearchedShortcutFragment>) {
      return {
        ...state,
        shortcutAdded: action.payload,
      };
    },
    editShortcutFromOtcScriptPad(
      state,
      action: PayloadAction<EditShortcutFromScriptPadInput>
    ) {
      if (state.otcScriptPadSelectedShortcut) {
        return {
          ...state,
          addOrEditShortcutMode: AddOrEditShortcutModes.EDIT,
          showAddOrEditShortcut: true,
          shortcutBeingEdited: {
            ...state.otcScriptPadSelectedShortcut,
            note: action.payload.note,
            isDeleted: false,
            createdBy: '',
          },
        };
      }

      return state;
    },
    selectShortcutOnOtcScriptPad(
      state,
      action: PayloadAction<SelectedShortcut>
    ) {
      return {
        ...state,
        otcScriptPadSelectedShortcut: action.payload,
      };
    },
    updateCurrentShortcutNoteText(state, action: PayloadAction<string>) {
      return {
        ...state,
        currentShortcutNoteTextBeingEdited: action.payload,
      };
    },
  },
});

export const { reducer: ManageShortcuts, actions: ManageShortcutActions } =
  ManageShortcutsSlice;
