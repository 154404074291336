import styled from 'styled-components';
import { theme } from '@covetrus/design-system-library';

export const BottomBarStyled = styled.div`
  background-color: ${theme.color.brandPrimary800};
  min-height: 32px;
  z-index: 50;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  color: '#fff';
`;

export const BottomBarListsStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  font-size: small;
  margin: auto;

  div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media only screen and (min-width: 768px) {
    display: inline-flex;

    div {
      margin-bottom: 0;
    }
  }
`;

export default BottomBarStyled;
