import { makeVar, useReactiveVar } from '@apollo/client';
import type { ReactiveVar } from '@apollo/client';

export enum ConnectionOptions {
  COVETRUS_USER = 'CovetrusUsersDB',
  OKTA_USER = 'okta',
}

export const initialConnection = ConnectionOptions.COVETRUS_USER;

export const connectionReactiveVar: ReactiveVar<ConnectionOptions> =
  makeVar<ConnectionOptions>(initialConnection);

export const getConnection = () => {
  const connection = useReactiveVar(connectionReactiveVar);
  return connection;
};

export const updateConnection = (connectionOption: ConnectionOptions): void => {
  connectionReactiveVar(connectionOption);
};
