import styled from 'styled-components';
import { theme } from '@covetrus/design-system-library';

export const LeftItemsStyled = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 20px;
  padding-top: 12px;
  cursor: pointer;
`;

export const RightItemsStyled = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 20px;
`;

export const AvatarGroupStyled = styled.div`
  align-items: center;
  display: flex;

  .avatar-circle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avatar-circle-initials {
    color: ${theme.color.neutralWhite};
  }
  p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;

export const AutocompleteStyled = styled.div`
  width: 400px;
`;
