import React, { useEffect } from 'react';
import { Button } from '@covetrus/design-system-library';
import { useIntl } from 'react-intl';
import {
  BodyContainerStyled,
  ColumnStyled,
  HeaderStyled,
  InternalLogoutStyled,
  LoginHeaderStyled,
  MessageStyled,
  SeparatorStyled,
} from './styles/InternalLogoutStyled';
import Logo from './assets/logo_rx.png';
import RxImage from './assets/image_rx.png';
import { internalLoginPath } from '../../constants/Constants';
import {
  InternalUserStatus,
  updateInternalLoginStatus,
} from '../../State/InternalUserAuthStatus.state';

const InternalLogout: React.FC = () => {
  const intl = useIntl();
  useEffect(() => {
    updateInternalLoginStatus(InternalUserStatus.LOGGED_OUT);
  });
  return (
    <InternalLogoutStyled>
      <HeaderStyled>
        <img
          src={Logo}
          alt={intl.formatMessage({ defaultMessage: 'Covetrus Logo' })}
        />
      </HeaderStyled>
      <BodyContainerStyled>
        <img
          src={RxImage}
          alt={intl.formatMessage({ defaultMessage: 'Image with dog' })}
        />
        <SeparatorStyled />
        <ColumnStyled>
          <LoginHeaderStyled>Login</LoginHeaderStyled>
          <MessageStyled>As a Covetrus user, login with Okta</MessageStyled>
          <Button
            onClick={() =>
              window.location.replace(
                `${window.location.origin}${internalLoginPath}`
              )
            }
          >
            {intl.formatMessage({ defaultMessage: 'Log back in with Okta' })}
          </Button>
        </ColumnStyled>
      </BodyContainerStyled>
    </InternalLogoutStyled>
  );
};

export default InternalLogout;
