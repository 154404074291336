/* eslint-disable no-param-reassign */

import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store/Store';

export interface UserState {
  isCorporate: boolean;
  isPractice: boolean;
  isInternal: boolean;
}

const initialState: UserState = {
  isCorporate: false,
  isPractice: false,
  isInternal: false,
};

export const UserStatusSlice = createSlice({
  initialState,
  name: 'userStatus',
  reducers: {
    setIsCorporate(state, action: PayloadAction<boolean>) {
      state.isCorporate = action.payload;
    },
    setIsPractice(state, action: PayloadAction<boolean>) {
      state.isPractice = action.payload;
    },
    setIsInternal(state, action: PayloadAction<boolean>) {
      state.isInternal = action.payload;
    },
    setUserStatuses(state, action: PayloadAction<UserState>) {
      state.isCorporate = action.payload.isCorporate;
      state.isInternal = action.payload.isInternal;
      state.isPractice = action.payload.isPractice;
    },
  },
});

export const { reducer: UserStatus, actions: UserActions } = UserStatusSlice;

export const isInternalOrIsPracticeSelector = createSelector(
  [
    (state: RootState) => state.UserStatus.isInternal,
    (state: RootState) => state.UserStatus.isPractice,
  ],
  (internalUser, practiceUser) => ({
    internalUser,
    practiceUser,
  })
);
