/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface RenewalsState {
  isProductDetailsOpen: boolean;
}

const initialState: RenewalsState = {
  isProductDetailsOpen: false,
};

export const RenewalsSlice = createSlice({
  initialState,
  name: 'renewals',
  reducers: {
    setIsProductDetailsOpen(state, action: PayloadAction<boolean>) {
      state.isProductDetailsOpen = action.payload;
    },
  },
});

export const { reducer: Renewals, actions: RenewalsActions } = RenewalsSlice;
