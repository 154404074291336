/* eslint-disable irx/pascal-filenames */
import React from 'react';
import { Route } from 'react-router-dom';
import type { ReactElement } from 'react';
import { Permission } from '@covetrus/reference-data';
import { DynamicFeatureFlags } from '../config/flags';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import HandleLogout from '../components/InternalLogout/HandleLogout';
import lazyWithRefresh from '../utils/lazyWithRefresh';

const PracticeSearchImport = () =>
  import('../domains/Practice/PracticeSearch/PracticeSearch');
const PracticeSettingsImport = () =>
  import('../domains/Practice/PracticeSettings/PracticeSettings');
const PartnerApiSearchImport = () =>
  import('../domains/PartnerApi/PartnerApiSearch/PartnerApiSearch');
const myAccountImport = () => import('../domains/MyAccount/MyAccount');
const userManagementImport = () =>
  import('../domains/Users/UserManagement/UserManagement');
const userProfileImport = () =>
  import('../domains/Users/UserProfile/UserProfileResolver');
const clientListImport = () =>
  import('../domains/Client/ClientList/ClientList');
const clientProfileImport = () =>
  import('../domains/Client/ClientProfile/ClientProfileContainer');
const patientProfileImport = () =>
  import('../domains/Patient/PatientProfile/PatientProfileContainer');
const createRxImport = () => import('../domains/Patient/CreateRx/CreateRx');
const RxDetailsImport = () => import('../domains/RxDetails/RxDetailsContainer');
const ReportingImport = () => import('../domains/Reporting/Reporting');
const ClientRequestsImport = () =>
  import('../domains/ClientRequests/ClientRequests');
const OrderHistoryImport = () => import('../domains/OrderHistory/OrderHistory');
const OrderDetailsImport = () => import('../domains/OrderDetails/OrderDetails');
const RxHistoryImport = () => import('../domains/RxHistory/RxHistory');
const ProductCatalogImport = () =>
  import('../domains/ProductCatalog/ProductCatalog');
const ApproveClientRequestImport = () =>
  import('../domains/ApproveClientRequest/ApproveClientRequest');
const ProScriptRenewalsImport = () =>
  import('../domains/ProScriptRenewals/ProScriptRenewals');
const LinkingCenterImport = () =>
  import('../domains/LinkingCenter/Patient/LinkingPatientList');
const ManageDraftsImport = () => import('../domains/ManageDrafts/ManageDrafts');
const CatalogManagementImport = () =>
  import('../domains/CatalogManagement/CatalogManagementContainer');
const CustomReportingImport = () =>
  import('../domains/CustomReporting/CustomReporting');
const AnalyticsImport = () =>
  import('../domains/AnalyticsDashboard/AnalyticsDashboard');

const PracticeSearch = lazyWithRefresh(PracticeSearchImport);
const PracticeSettings = lazyWithRefresh(PracticeSettingsImport);
const PartnerApiSearch = lazyWithRefresh(PartnerApiSearchImport);
const MyAccount = lazyWithRefresh(myAccountImport);
const UserManagement = lazyWithRefresh(userManagementImport);
const UserProfile = lazyWithRefresh(userProfileImport);
const ClientList = lazyWithRefresh(clientListImport);
const ClientProfile = lazyWithRefresh(clientProfileImport);
const PatientProfile = lazyWithRefresh(patientProfileImport);
const CreateRx = lazyWithRefresh(createRxImport);
const RxDetails = lazyWithRefresh(RxDetailsImport);
const Reporting = lazyWithRefresh(ReportingImport);
const ClientRequests = lazyWithRefresh(ClientRequestsImport);
const OrderHistory = lazyWithRefresh(OrderHistoryImport);
const OrderDetails = lazyWithRefresh(OrderDetailsImport);
const RxHistory = lazyWithRefresh(RxHistoryImport);
const ProductCatalog = lazyWithRefresh(ProductCatalogImport);
const ApproveClientRequest = lazyWithRefresh(ApproveClientRequestImport);
const ProScriptRenewals = lazyWithRefresh(ProScriptRenewalsImport);
const LinkingCenter = lazyWithRefresh(LinkingCenterImport);
const ManageDrafts = lazyWithRefresh(ManageDraftsImport);
const CatalogManagement = lazyWithRefresh(CatalogManagementImport);
const CustomReporting = lazyWithRefresh(CustomReportingImport);
const Analytics = lazyWithRefresh(AnalyticsImport);

const internalUserPaths = (): ReactElement => {
  return (
    <>
      <Route path='/my-account' element={<MyAccount />} />
      <Route
        path='/'
        element={
          <ProtectedRoute allowedPermissions={[Permission.READ_PRACTICE]}>
            <PracticeSearch />
          </ProtectedRoute>
        }
      />
      <Route
        path='/internal-user/practice-settings/:acbKey'
        element={<PracticeSettings />}
      />
      <Route
        path='/internal-user/partner-api-keys'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.READ_RXACCESS_PARTNER,
              Permission.MANAGE_RXACCESS_PARTNER,
            ]}
            flag={DynamicFeatureFlags.RXACCESS_PARTNER_API_INTERNAL}
          >
            <PartnerApiSearch />
          </ProtectedRoute>
        }
      />
      <Route
        path='/internal-user/partner-api-keys/:rxAccessPartnerApiKeyKey'
        element={
          <ProtectedRoute
            allowedPermissions={[Permission.MANAGE_RXACCESS_PARTNER]}
            flag={DynamicFeatureFlags.RXACCESS_PARTNER_API_INTERNAL}
          >
            <PartnerApiSearch />
          </ProtectedRoute>
        }
      />
      <Route
        path='/users/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.READ_PRACTICE_USER,
              Permission.MANAGE_PRACTICE_USER,
            ]}
          >
            <UserManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path='/user/:acbKey/:userKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.MANAGE_PRACTICE_USER,
              Permission.READ_PRACTICE_USER,
            ]}
          >
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/client-list/:acbKey/'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.MANAGE_CLIENTS,
              Permission.READ_CLIENTS,
            ]}
          >
            <ClientList />
          </ProtectedRoute>
        }
      />
      <Route
        path='/client/:acbKey/:clientKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.MANAGE_CLIENTS,
              Permission.READ_CLIENTS,
            ]}
          >
            <ClientProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/patient/:acbKey/:patientKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.MANAGE_PATIENTS,
              Permission.READ_PATIENTS,
            ]}
          >
            <PatientProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/reporting/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.READ_REPORTS_AGGREGATE,
              Permission.READ_REPORTS_FINANCIAL,
              Permission.READ_REPORTS_KPI,
            ]}
          >
            <Reporting />
          </ProtectedRoute>
        }
      />
      <Route
        path='/rx-details/:acbKey/:prescriptionTypeCode/:prescriptionKey'
        element={
          <ProtectedRoute allowedPermissions={[Permission.READ_PRESCRIPTION]}>
            <RxDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path='/rx-details/:acbKey/approval/:approvalKey'
        element={
          <ProtectedRoute allowedPermissions={[Permission.READ_PRESCRIPTION]}>
            <RxDetails />
          </ProtectedRoute>
        }
      />
      <Route path='/create-rx' element={<CreateRx />} />
      <Route path='/create-rx/:step' element={<CreateRx />} />
      <Route
        path='/client-requests/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.READ_PRESCRIPTION,
              Permission.MANAGE_PRESCRIPTION,
              Permission.MANAGE_PRESCRIPTION_PHARM_TECH,
            ]}
          >
            <ClientRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path='/order-history/:acbKey'
        element={
          <ProtectedRoute allowedPermissions={[Permission.READ_ORDER_DETAILS]}>
            <OrderHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/order-details/:acbKey/:orderNumber'
        element={
          <ProtectedRoute allowedPermissions={[Permission.READ_ORDER_DETAILS]}>
            <OrderDetails />
          </ProtectedRoute>
        }
      />
      <Route path='/logout/internal' element={<HandleLogout />} />
      <Route path='/rx-history/:acbKey' element={<RxHistory />} />
      <Route
        path='/product-catalog/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.READ_PRODUCTS,
              Permission.READ_PRACTICE,
              Permission.READ_PRACTICE_PRICING,
            ]}
          >
            <ProductCatalog />
          </ProtectedRoute>
        }
      />
      <Route
        path='/approve-client-request/:approvalKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.MANAGE_PRESCRIPTION,
              Permission.MANAGE_PRESCRIPTION_DELEGATE,
              Permission.MANAGE_PRESCRIPTION_PHARM_TECH,
              Permission.READ_PRESCRIPTION,
            ]}
          >
            <ApproveClientRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/proscript-renewals/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[Permission.READ_DRAFT_PRESCRIPTION]}
          >
            <ProScriptRenewals />
          </ProtectedRoute>
        }
      />
      <Route
        path='/linking/patient/list/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.MANAGE_PATIENTS,
              Permission.READ_PATIENTS,
            ]}
          >
            <LinkingCenter />
          </ProtectedRoute>
        }
      />
      <Route
        path='/manage-drafts/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.MANAGE_DRAFT_PRESCRIPTION,
              Permission.READ_DRAFT_PRESCRIPTION,
            ]}
          >
            <ManageDrafts />
          </ProtectedRoute>
        }
      />
      <Route
        path='/catalog-management/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[
              Permission.READ_STOREFRONT,
              Permission.MANAGE_STOREFRONT,
            ]}
          >
            <CatalogManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path='/custom-reports/:acbKey'
        element={
          <ProtectedRoute allowedPermissions={[Permission.READ_REPORTS_KPI]}>
            <CustomReporting />
          </ProtectedRoute>
        }
      />
      <Route
        path='/analytics-dashboard/:acbKey'
        element={
          <ProtectedRoute
            allowedPermissions={[Permission.READ_REPORTS_AGGREGATE]}
          >
            <Analytics />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default internalUserPaths;
