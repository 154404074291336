/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SortState } from '../../domains/Practice/PracticeSearch/Table/types/SortState';
import { SortDirection } from '../../domains/Practice/PracticeSearch/Table/types/SortState';

export interface PartnerApiSearchStatus {
  currentPage: number;
  pageSize: number;
  search: string;
  sort?: SortState;
}

export interface PartnerApiSearch {
  partnerApiSearchStatus: PartnerApiSearchStatus;
}

const initialState: PartnerApiSearch = {
  partnerApiSearchStatus: {
    currentPage: 0,
    pageSize: 10,
    search: '',
    sort: { organizationName: SortDirection.asc },
  },
};

export const PartnerApiSearchStatusSlice = createSlice({
  initialState,
  name: 'PartnerApiSearchStatus',
  reducers: {
    setPartnerApiSearchList(state, action: PayloadAction<PartnerApiSearch>) {
      state.partnerApiSearchStatus.currentPage =
        action.payload.partnerApiSearchStatus.currentPage;
      state.partnerApiSearchStatus.pageSize =
        action.payload.partnerApiSearchStatus.pageSize;
      state.partnerApiSearchStatus.search =
        action.payload.partnerApiSearchStatus.search;
      state.partnerApiSearchStatus.sort =
        action.payload.partnerApiSearchStatus.sort;
    },
  },
});

export const {
  reducer: PartnerApiSearchStatus,
  actions: PartnerApiSearchStatusActions,
} = PartnerApiSearchStatusSlice;
