import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Locales } from '@covetrus/reference-data';
import type { NavigateFunction } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { createIntl } from '@formatjs/intl';
import type { MessageFormatElement } from 'react-intl';
import {
  GenericSpinner,
  PageError,
  Button,
} from '@covetrus/design-system-library';
import loadLocaleData from '../../intl/loadIntl';
import Locale from '../../intl/types/Locale.types';
import { internalLogoutPath } from '../../constants/Constants';
import Store from '../../redux/store/Store';
import type GenericErrorProps from './types/GenericErrorProps';
import sendError from '../ErrorBoundary/sendError';
import type { ErrorLog } from '../ErrorBoundary/ErrorBoundary';

const GenericError: React.FC<GenericErrorProps> = ({ errorBoundaryError }) => {
  const [messages, setMessages] = useState<
    Record<string, Array<MessageFormatElement>>
  >({});

  useEffect(() => {
    let isSubscribed = true;
    loadLocaleData(Locales.en_US)
      .then((result) => isSubscribed && setMessages(result.default))
      .catch((error) => console.error(error));
    return () => {
      isSubscribed = false;
    };
  }, []);

  if (Object.values(messages).length)
    return (
      <Loaded messages={messages} errorBoundaryError={errorBoundaryError} />
    );

  return <GenericSpinner />;
};

const Loaded: React.FC<{
  messages: Record<string, Array<MessageFormatElement>>;
  errorBoundaryError?: ErrorLog;
}> = ({
  messages,
  errorBoundaryError,
}: {
  messages: Record<string, Array<MessageFormatElement>>;
  errorBoundaryError?: ErrorLog;
}) => {
  const intl = createIntl({ messages, locale: Locale['en-US'] });
  let navigate: NavigateFunction;

  try {
    navigate = useNavigate();
  } catch (e: unknown) {
    console.error('Navigate not instantiated');
  }
  const { logout, getAccessTokenSilently } = useAuth0();
  const internalUser = Store.getState().UserStatus.isInternal;
  const doLogout = () => {
    if (internalUser) {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}${internalLogoutPath}`,
        },
      });
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };
  useEffect(() => {
    if (errorBoundaryError) {
      getAccessTokenSilently().then((token) =>
        sendError(errorBoundaryError, token)
      );
    }
  }, []);
  return (
    <PageError
      headerText={intl.formatMessage({
        defaultMessage: 'Something Went Wrong',
      })}
      subHeaderText={
        <span>
          {intl.formatMessage({
            defaultMessage:
              'The server encountered an internal error and was unable to complete your request.',
          })}
          {'\n'}
          {'\n'}
          <span
            style={{
              display: 'inline-block',
              marginLeft: '30px',
              marginRight: '30px',
              boxSizing: 'border-box',
            }}
          >
            {intl.formatMessage({
              defaultMessage:
                'Please Contact the administrator to inform them of this error.',
            })}
          </span>
        </span>
      }
      actionButton={
        <Button
          onClick={() => (navigate ? navigate(-1) : window.history.go(-1))}
        >
          {intl.formatMessage({ defaultMessage: 'Go Back' })}
        </Button>
      }
      secondActionButton={
        <Button onClick={() => doLogout()}>
          {intl.formatMessage({ defaultMessage: 'Logout' })}
        </Button>
      }
    />
  );
};

export default GenericError;
