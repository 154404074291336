import React from 'react';
import { useSelector } from 'react-redux';
import { theme, useMediaQuery } from '@covetrus/design-system-library';
import PracticeSelector from './PracticeSelector';
import type { RootState } from '../../redux/store/Store';
import PracticeSelectorStyled from './styles/PracticeSelectorStyled';
import usePracticeSection from '../../hooks/usePracticeSelection/usePracticeSelection';

const PracticeSelectorContainerForMobileView = () => {
  const { practiceOptions } = usePracticeSection();
  const isMobile = useMediaQuery(
    `(max-width: ${theme.displaySizes.smartphones})`
  );

  const practiceUser = useSelector(
    (state: RootState) => state.UserStatus.isPractice
  );

  const internalUser = useSelector(
    (state: RootState) => state.UserStatus.isInternal
  );
  return (
    isMobile &&
    !internalUser &&
    practiceOptions.length > 1 && (
      <PracticeSelectorStyled className='practice-selector-gap'>
        <PracticeSelector isPracticeUser={practiceUser} />
      </PracticeSelectorStyled>
    )
  );
};
export default PracticeSelectorContainerForMobileView;
