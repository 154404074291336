import styled from 'styled-components';
import { theme } from '@covetrus/design-system-library';

const TopBarStyled = styled.div`
  background-color: ${theme.color.brandPrimary800};
  height: 64px;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default TopBarStyled;
