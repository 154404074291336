import styled from 'styled-components';

const LoaderStyled = styled.div`
height: 100vh;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export default LoaderStyled;