import * as React from 'react';
import type SvgType from '@covetrus/design-system-library/dist/assets/icons/types/SvgType';

const DashboardIcon = ({ fill, width, height }: SvgType) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox='0 0 23 23'
      fill={fill || 'currentColor'}
    >
      <defs>
        <filter id='prefix__a' colorInterpolationFilters='auto'>
          <feColorMatrix
            in='SourceGraphic'
            values='0 0 0 0 0.078431 0 0 0 0 0.454902 0 0 0 0 0.898039 0 0 0 1.000000 0'
          />
        </filter>
        <filter id='prefix__b' colorInterpolationFilters='auto'>
          <feColorMatrix
            in='SourceGraphic'
            values='0 0 0 0 0.078431 0 0 0 0 0.454902 0 0 0 0 0.898039 0 0 0 1.000000 0'
          />
        </filter>
        <path
          id='prefix__c'
          d='M19.44 0c1.141 0 2.075.885 2.155 2.006l.005.154v11.52a2.161 2.161 0 01-2.006 2.155l-.154.005h-6.025l.182 1.455h2.243a.72.72 0 01.084 1.435l-.084.005H5.76a.72.72 0 01-.084-1.435l.084-.005h2.242l.182-1.455H2.16a2.161 2.161 0 01-2.155-2.006L0 13.68V2.16C0 1.019.885.085 2.006.005L2.16 0h17.28zm-7.477 15.84H9.635l-.18 1.44h2.689l-.181-1.44zM10.8 12.96a.72.72 0 100 1.44.72.72 0 000-1.44zm8.64-11.52H2.16a.718.718 0 00-.713.622l-.007.098-.001 9.359h18.72l.001-9.359a.72.72 0 00-.72-.72z'
        />
      </defs>
      <g transform='translate(-12 -8)' fill='currentColor' fillRule='evenodd'>
        <g transform='translate(12 8)'>
          <use
            fill={fill || 'currentColor'}
            fillRule='nonzero'
            xlinkHref='#prefix__c'
            transform='translate(1.53 2.28)'
          />
        </g>
      </g>
    </svg>
  );
};

export default DashboardIcon;
