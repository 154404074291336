import { ContextCode } from './types';
import type { PracticeOption, UserContext } from './usePracticeSelection';

const practiceOptionsFromContextList = (
  contexts: Array<UserContext>
): Array<PracticeOption> =>
  contexts
    .filter((context) => {
      // filter where context code is practice and the acbKey exists.
      return (
        context.userContextCode === ContextCode.Practice &&
        context.animalCareBusinessKey
      );
    })
    .map((context) => ({
      label: context.contextDescription,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      value: context.animalCareBusinessKey as string,
      contextKey: context.contextKey,
    }));

export default practiceOptionsFromContextList;
