/* eslint-disable no-param-reassign */
/**
 *  Redux toolkit uses immer which allows for state reassignment and direct state mutations. it makes a copy under the hood
 * https://redux-toolkit.js.org/usage/immer-reducers
 */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { NoteContextCode } from '../../api/generated/graphql';
import PatientNoteModes from '../../domains/Patient/PatientProfile/AddOrEditPatientNote/types/PatientNoteModes';
import PatientSliderCloseType from '../../domains/Patient/PatientProfile/AddOrEditPatientNote/types/PatientSliderCloseType';

export interface CurrentNote {
  initialNote: string;
  initialContextCode: NoteContextCode | null;
  noteKey: string | null;
  mode: PatientNoteModes;
  userKey: string | null;
}

export interface EditNote {
  note: string;
  contextCode: NoteContextCode;
  noteKey: string;
  userKey: string;
}

export interface PatientProfileState {
  notesCurrentPage: number;
  showAddOrEditNote: boolean;
  currentNote: CurrentNote;
}

const initialCurrentNote = {
  initialNote: '',
  noteKey: null,
  initialContextCode: null,
  mode: PatientNoteModes.CREATE,
  userKey: null,
};

const initialState: PatientProfileState = {
  notesCurrentPage: 0,
  showAddOrEditNote: false,
  currentNote: initialCurrentNote,
};

export const PatientProfileSlice = createSlice({
  initialState,
  name: 'patientProfile',
  reducers: {
    setPatientProfileState(
      state,
      action: PayloadAction<Partial<PatientProfileState>>
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
    closeNoteSlider(state, action: PayloadAction<PatientSliderCloseType>) {
      state.showAddOrEditNote = false;
      state.currentNote = initialCurrentNote;
      if (action.payload === PatientSliderCloseType.FIRST_PAGE)
        state.notesCurrentPage = 0;
    },
    editNote(state, action: PayloadAction<EditNote>) {
      // Below we can use reassignment because of immer.
      state.currentNote.initialContextCode = action.payload.contextCode;
      state.currentNote.initialNote = action.payload.note;
      state.currentNote.noteKey = action.payload.noteKey;
      state.showAddOrEditNote = true;
      state.currentNote.userKey = action.payload.userKey;
      state.currentNote.mode = PatientNoteModes.EDIT;
    },
    createNote(state) {
      state.currentNote = initialCurrentNote;
      state.showAddOrEditNote = true;
    },
  },
});

export const { reducer: PatientProfile, actions: PatientProfileActions } =
  PatientProfileSlice;
