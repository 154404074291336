import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import type { AppState } from '@auth0/auth0-react';
import config from './config/config';
import { useNavigateNoUpdates } from './contexts/NavigatorContextProvider';

const auth0Domain =
  config[process.env.REACT_APP_ENV || 'local']?.auth0Domain ?? '';
const auth0ClientId =
  config[process.env.REACT_APP_ENV || 'local']?.auth0ClientId ?? '';

const Auth0ProviderWithNavigate = (props: React.PropsWithChildren) => {
  const { children } = props;
  const navigate = useNavigateNoUpdates();

  const authorizationParams = {
    redirect_uri: window.location.origin,
    audience: 'https://delorean-corporate-portal',
  };

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(
      appState?.returnTo ||
        `${window.location.pathname}${window.location.search}`
    );
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      cacheLocation='memory'
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
