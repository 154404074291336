import { theme } from '@covetrus/design-system-library';
import styled from 'styled-components';

const PracticeSelectorStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media (max-width: ${theme.displaySizes.smartphones}) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export default PracticeSelectorStyled;
