/* eslint-disable */

export type Auth0ContextUser = {
  email: string;
  nickname: string;
  name: string;
  email_verified: boolean;
  picture: string;
  updated_at: string;
};

export enum ContextCode {
  Corporate = 'CORPORATE_GROUP',
  Internal = 'COVETRUS',
  Practice = 'PRACTICE',
}
