import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loadMessages as loadDslMessages } from '@covetrus/design-system-library';
import { IntlProvider } from 'react-intl';
import type { MessageFormatElement } from 'react-intl';
import App from './routes/Routes';
import CustomApolloProvider from './api/CustomApolloProvider';
import type { RootState } from './redux/store/Store';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import loadLocaleData from './intl/loadIntl';
import './scripts/pendoSnippet';

const MainApp: React.FC = () => {
  const locale = useSelector((state: RootState) => state.Language.locale);
  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, Array<MessageFormatElement>>
  >();

  const getMessages = async () => {
    const loadedMessages = await Promise.all([
      loadLocaleData(locale),
      loadDslMessages(locale),
    ]).then((results) => {
      return {
        ...results[0],
        ...results[1],
      };
    });
    return setMessages(loadedMessages.default);
  };

  useEffect(() => {
    getMessages();
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
      <CustomApolloProvider>
        <ScrollToTop />
        <App />
      </CustomApolloProvider>
    </IntlProvider>
  );
};

export default MainApp;
