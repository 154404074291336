import React from 'react';

const PersonIcon: React.FC = () => (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>283A6068-E245-4DF7-B8D1-C3FC8F6E51DD</title>
    <defs>
      <path
        id='person-icon'
        d='M9.49136972,0.000869719047 C14.5399197,0.071069719 18.6506697,4.18181972 18.7199697,9.23126972 C18.7919697,14.4953697 14.4953697,18.7928697 9.23036972,18.7208697 C4.18181972,18.6506697 0.071069719,14.5399197 0.000869719047,9.49136972 C-0.070230281,4.22726972 4.22726972,-0.070230281 9.49136972,0.000869719047 Z M9.34151972,1.44084519 C4.96076972,1.45166972 1.36751972,5.11691972 1.44086972,9.49586972 C1.47304245,11.4386533 2.22102338,13.3012348 3.54146972,14.7266697 C3.57775995,14.7659141 3.62961477,14.7869999 3.68299422,14.7842179 C3.73637367,14.7814359 3.78575564,14.755074 3.81776972,14.7122697 C4.22029165,14.185796 4.71321228,13.7350822 5.27351972,13.3811697 C6.41921972,12.6458697 7.87091972,12.2408697 9.36086972,12.2408697 C10.8508197,12.2408697 12.3025197,12.6458697 13.4482197,13.3811697 C14.0085475,13.7352393 14.5014668,14.1861042 14.9039697,14.7127197 C14.9359838,14.755524 14.9853658,14.7818859 15.0387452,14.7846679 C15.0921247,14.7874499 15.1439795,14.7663641 15.1802697,14.7271197 C16.5320793,13.2672384 17.2823788,11.350503 17.280872,9.36086972 C17.280872,4.98731972 13.7222697,1.43006972 9.34151972,1.44084519 Z M9.36086972,4.32086972 C10.2424197,4.32086972 11.0434197,4.65566972 11.6158197,5.26316972 C12.1954197,5.87876972 12.4780197,6.70676972 12.4114197,7.59506972 C12.2782197,9.36086972 10.9124697,10.8008697 9.36086972,10.8008697 C7.80926972,10.8008697 6.44081972,9.36086972 6.31031972,7.59461972 C6.24596972,6.69776972 6.53081972,5.86211972 7.10096972,5.25776972 C7.67111972,4.65341972 8.47346972,4.32086972 9.36086972,4.32086972 Z'
      />
    </defs>
    <g
      id='Phase-1'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Screens-/-1280-/-Dashboard-/-Now-Viewing-/-Account-Dropdown-/-Hover'
        transform='translate(-1086.000000, -75.000000)'
      >
        <g
          id='Dropdown-/-Account'
          transform='translate(1064.000000, 57.000000)'
        >
          <g
            id='Menus/List-Item/Text-Only'
            transform='translate(8.000000, 8.000000)'
          >
            <g
              id='person-circle-outline'
              transform='translate(14.294130, 10.294130)'
            >
              <mask id='mask-2' fill='white'>
                <use xlinkHref='#person-icon' />
              </mask>
              <use
                id='Mask'
                fill='#000000'
                fillRule='nonzero'
                xlinkHref='#person-icon'
              />
              <g
                id='🚫-color-swatch'
                mask='url(#mask-2)'
                fill='#1474E5'
                fillRule='nonzero'
              >
                <g
                  transform='translate(-2.294130, -2.294130)'
                  id='🩸Icon-Color'
                >
                  <rect x='0' y='0' width='24' height='24' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PersonIcon;
