import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PageError, Button, ErrorTypes } from '@covetrus/design-system-library';

const NotFound: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <PageError
      headerText={intl.formatMessage({
        defaultMessage: '404 Page Not Found',
      })}
      errorType={ErrorTypes.ERROR_404}
      subHeaderText={intl.formatMessage({
        defaultMessage:
          "404 We can't seem to find the page you are looking for. \n Try going back to the previous page.",
      })}
      actionButton={
        <Button onClick={() => navigate(-1)}>
          {intl.formatMessage({ defaultMessage: 'Go Back' })}
        </Button>
      }
    />
  );
};

export default NotFound;
