import { useReactiveVar } from '@apollo/client';
import { Toast } from '@covetrus/design-system-library';
import React from 'react';
import styled from 'styled-components';
import reactiveToastState from './toaster.state';

const Toaster = () => {
  const toasts = useReactiveVar(reactiveToastState);
  return (
    <ToasterStyled>
      {toasts.map((toastProps) => (
        <ToastSpacerStyled key={toastProps.id}>
          <Toast {...toastProps} />
        </ToastSpacerStyled>
      ))}
    </ToasterStyled>
  );
};

const ToastSpacerStyled = styled.div`
  pointer-events: all;
  margin-bottom: 8px;
`;

const ToasterStyled = styled.div`
  z-index: 10000;
  pointer-events: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
`;

export default Toaster;
