import axios from 'axios';
import config from '../../config/config';
import type { ErrorLog } from './ErrorBoundary';
import {
  reactiveCurrentContext,
  reactiveCurrentPractice,
} from '../../hooks/usePracticeSelection/usePracticeSelection';
import { ContextCode } from '../../hooks/usePracticeSelection/types';

const sendError = async ({ error, stack, cause }: ErrorLog, token: string) => {
  const currentContext = reactiveCurrentContext();
  const currentPractice = reactiveCurrentPractice();

  try {
    await axios.post(
      `${config[process.env.REACT_APP_ENV || 'local'].errors}`,
      {
        error,
        stack,
        cause,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(currentContext?.contextKey && {
            ContextKey: currentContext.contextKey,
          }),
          ...(currentContext?.userContextCode === ContextCode.Corporate &&
            currentPractice.value && {
              PracticeKey: currentPractice.value,
            }),
        },
      }
    );
  } catch (e: unknown) {
    console.error(e);
  }
};

export default sendError;
