import { createSearchParams } from 'react-router-dom';
import {
  useNavigateNoUpdates,
  useSearchParamsWithSelectUpdates,
} from '../../../../contexts/NavigatorContextProvider';

export type ScriptPadParams = Record<string, string>;
export interface UseScriptPadParamsInput {
  subscribingParams?: Set<string>;
  updateOnAnyParamChange?: boolean;
}

interface ScriptPadHook {
  getScriptPadParams: () => ScriptPadParams;
  getReducedScriptPadParams: () => ScriptPadParams;
  patchScriptPadParams: (params: Record<string, string>) => void;
  getScriptPadSearchString: (params: ScriptPadParams) => string;
  navigateScriptPad: (destination: string, params?: ScriptPadParams) => void;
  setScriptPadParams: (params: ScriptPadParams) => void;
  removeScriptPadParams: (paramKeys: Array<string>) => void;
  /** Reduces script pad barams down to
   * {@link basicParams}
   * and concatenates params
   */
  reduceScriptPadParams: (params: ScriptPadParams) => void;
}

/**
 *  @link basicParams
 */
const basicParams = [
  'sourceRoute',
  'searchPatient',
  'patientKey',
  'clientKey',
  'searchProduct',
  'prescriptionType',
  'acbKey',
];

const useScriptPadParams = ({
  subscribingParams,
  updateOnAnyParamChange,
}: UseScriptPadParamsInput): ScriptPadHook => {
  const [searchParams, setSearchParams, { getScriptPadParamsNoRender }] =
    useSearchParamsWithSelectUpdates({
      subscribingParams,
      updateOnAnyParamChange,
    });
  const navigate = useNavigateNoUpdates();

  const getScriptPadParams = () => {
    const params: ScriptPadParams = { ...searchParams };
    return params;
  };

  const getReducedScriptPadParams = (): ScriptPadParams => {
    const paramsAfter: ScriptPadParams = {};
    const paramsBefore: ScriptPadParams = { ...getScriptPadParamsNoRender() };

    basicParams.forEach((key) => {
      if (paramsBefore[key]) paramsAfter[key] = paramsBefore[key];
    });

    return paramsAfter;
  };

  const reduceScriptPadParams = (params: ScriptPadParams) => {
    setSearchParams((paramsBefore) => {
      const paramsAfter: ScriptPadParams = {};
      basicParams.forEach((key) => {
        if (paramsBefore[key]) paramsAfter[key] = paramsBefore[key];
      });
      return { ...paramsAfter, ...params };
    });
  };

  const removeEmpties = (orig: ScriptPadParams) => {
    const copy = orig;

    Object.keys(copy).forEach((key) => {
      if (!copy[key]) {
        delete copy[key];
      }
    });

    return copy;
  };

  const stringifyAllFields = (orig: ScriptPadParams) => {
    const stringed: Record<string, string> = {};

    Object.keys(orig).forEach((key) => {
      stringed[key] = orig[key];
    });

    return stringed;
  };

  const setScriptPadParams = (params: ScriptPadParams) => {
    const emptiesRemoved = removeEmpties(params);
    const stringified = stringifyAllFields(emptiesRemoved);
    setSearchParams(stringified);
  };

  const getScriptPadSearchString = (params?: ScriptPadParams) => {
    const emptiesRemoved = removeEmpties(params ?? getScriptPadParams());
    const stringified = stringifyAllFields(emptiesRemoved);

    return createSearchParams(stringified).toString();
  };

  const navigateScriptPad = (destination: string, params?: ScriptPadParams) => {
    navigate({
      pathname: destination,
      search: getScriptPadSearchString(params),
    });
  };

  const patchScriptPadParams = (patchParams: Record<string, string>) => {
    setSearchParams((prevSearchParams) => {
      const updatedParams = {
        ...prevSearchParams,
        ...patchParams,
      };
      const emptiesRemoved = removeEmpties(updatedParams);
      return emptiesRemoved;
    });
  };

  const removeScriptPadParams = (paramKeys: Array<string>) => {
    setSearchParams((params) => {
      const copy = { ...params };
      paramKeys.forEach((key) => {
        delete copy[key];
      });
      return copy;
    });
  };

  return {
    getScriptPadParams,
    reduceScriptPadParams,
    getScriptPadSearchString,
    navigateScriptPad,
    patchScriptPadParams,
    setScriptPadParams,
    removeScriptPadParams,
    getReducedScriptPadParams,
  };
};

export default useScriptPadParams;
