import React from 'react';

const LogoutIcon: React.FC = () => (
  <svg
    width='19px'
    height='17px'
    viewBox='0 0 19 17'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>30C00DAE-AD5D-4760-B976-1A73BE596240</title>
    <defs>
      <path
        id='logout-icon'
        d='M9.36,4.4408921e-14 C10.741128,4.4408921e-14 12.1462955,1.0660697 12.2355142,2.38707928 L12.24,2.52 L12.24,4.32 C12.24,4.71764502 11.917645,5.04 11.52,5.04 C11.1507582,5.04 10.8464348,4.76205106 10.804844,4.40396721 L10.8,4.32 L10.8,2.52 C10.8,2.04742439 10.1342813,1.50094772 9.47049812,1.44473293 L9.36,1.44 L2.52,1.44 C1.9586188,1.44 1.49727695,1.86831942 1.44494394,2.41598879 L1.44,2.52 L1.44,13.32 C1.44,13.8813812 1.86831942,14.342723 2.41598879,14.3950561 L2.52,14.4 L9.72,14.4 C10.2813812,14.4 10.742723,13.9716806 10.7950561,13.4240112 L10.8,13.32 L10.8,11.52 C10.8,11.122355 11.122355,10.8 11.52,10.8 C11.8892418,10.8 12.1935652,11.0779489 12.235156,11.4360328 L12.24,11.52 L12.24,13.32 C12.24,14.6653657 11.1857201,15.764491 9.85826535,15.8362712 L9.72,15.84 L2.52,15.84 C1.17463435,15.84 0.0755089566,14.7857201 0.00372878116,13.4582653 L-2.39808173e-14,13.32 L-2.39808173e-14,2.52 C-2.39808173e-14,1.17463435 1.05427987,0.0755089566 2.38173465,0.00372878116 L2.52,4.4408921e-14 L9.36,4.4408921e-14 Z M14.8412876,3.75098732 L14.9091169,3.81088312 L18.5091169,7.41088312 C18.7686653,7.67043157 18.7886306,8.07883793 18.5690127,8.3612876 L18.5091169,8.42911688 L14.9091169,12.0291169 C14.6279394,12.3102944 14.1720606,12.3102944 13.8908831,12.0291169 C13.6313347,11.7695684 13.6113694,11.3611621 13.8309873,11.0787124 L13.8908831,11.0108831 L16.247,8.655 L5.76,8.655 C5.36235498,8.655 5.04,8.33264502 5.04,7.935 C5.04,7.5657582 5.31794894,7.26143484 5.67603279,7.21984397 L5.76,7.215 L16.277,7.215 L13.8908831,4.82911688 C13.6313347,4.56956843 13.6113694,4.16116207 13.8309873,3.8787124 L13.8908831,3.81088312 C14.1504316,3.55133467 14.5588379,3.5313694 14.8412876,3.75098732 Z'
      />
    </defs>
    <g
      id='Phase-1'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Screens-/-1280-/-Dashboard-/-Now-Viewing-/-Account-Dropdown-/-Hover'
        transform='translate(-1087.000000, -124.000000)'
      >
        <g
          id='Dropdown-/-Account'
          transform='translate(1064.000000, 57.000000)'
        >
          <g
            id='Menus/List-Item/Text-Only'
            transform='translate(8.000000, 56.000000)'
          >
            <g id='log-out-outline' transform='translate(15.030000, 11.780000)'>
              <mask id='mask-logout' fill='white'>
                <use xlinkHref='#logout-icon' />
              </mask>
              <use
                id='Mask'
                fill='#000000'
                fillRule='nonzero'
                xlinkHref='#logout-icon'
              />
              <g
                id='🚫-color-swatch'
                mask='url(#mask-logout)'
                fill='#1474E5'
                fillRule='nonzero'
              >
                <g
                  transform='translate(-3.030000, -3.780000)'
                  id='🩸Icon-Color'
                >
                  <rect x='0' y='0' width='24' height='24' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
