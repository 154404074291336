import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { FeatureFlagsState } from '../../types/featureFlags.types';
import { DynamicFeatureFlags } from '../../config/flags';

const initialState: FeatureFlagsState = {
  flags: {
    [DynamicFeatureFlags.COMPETITOR_PRICING]: false,
  },
  flagsLoading: true,
};

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => ({
      ...state,
      flags: action.payload,
    }),
    flagsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      flagsLoading: action.payload,
    }),
  },
});

export const { reducer: FeatureFlags, actions: FeatureFlagsActions } =
  featureFlagsSlice;
