import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureFlagInfoQuery } from '@api/generated/graphql';
import type { FeatureFlag } from '@api/generated/graphql';
import { RulesEngine } from '@covetrus/feature-flagger';
import { useParams } from 'react-router-dom';
import type PracticeKeyParams from '@vrxComponents/LeftNavigation/types/PracticeKeyParams';
import { useAuth0 } from '@auth0/auth0-react';
import sendError from '@vrxComponents/ErrorBoundary/sendError';
import usePermissions from '@vrxHooks/usePermissions';
import usePracticeSelection from '@vrxHooks/usePracticeSelection/usePracticeSelection';
import { useIntl } from 'react-intl';
import { addToast } from '@vrxComponents/Toaster/toaster.actions';
import { ToastType } from '@covetrus/design-system-library';
import { FeatureFlagsActions } from '../redux/slices/FeatureFlags.slice';

const useGetDynamicFeatureFlags = (ready: boolean): void => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const permissions = usePermissions(!ready);
  const { currentPractice } = usePracticeSelection();
  const { acbKey } = useParams<keyof PracticeKeyParams>() as PracticeKeyParams;
  const previousDataRef = useRef<string>('');
  const intl = useIntl();

  const practiceKey = acbKey ?? currentPractice.value ?? '';

  const { data, loading, refetch } = useFeatureFlagInfoQuery({
    skip: !ready,
    fetchPolicy: 'network-only',
    onError: (error) => {
      getAccessTokenSilently().then((token) => {
        sendError(
          error instanceof Error
            ? { error: error.message, stack: error.stack }
            : { error: String(error), stack: 'useGetDynamicFeatureFlags' },
          token
        );
      });
    },
  });

  const featureFlagsData = data?.featureFlags;
  const userTypeCodeData = data?.userType;

  const processTheFlags = (featureFlags: Array<FeatureFlag>) => {
    const cookieAcbo = localStorage.getItem('organizationKey');
    const stringPermissions = permissions.listPermissions.map((permission) =>
      permission.toString()
    );

    const userInput = {
      userPermissions: stringPermissions,
      acbKey: practiceKey,
      acboKey: cookieAcbo ?? undefined,
      userTypeCode: userTypeCodeData ?? '',
    };

    const processedFlags = featureFlags.map((flag) => {
      try {
        return {
          name: flag.name,
          featureEnabled: RulesEngine(flag, userInput),
        };
      } catch (error) {
        console.error('Error:', error);
        getAccessTokenSilently().then((token) => {
          sendError(
            error instanceof Error
              ? { error: error.message, stack: error.stack }
              : { error: String(error), stack: 'useGetDynamicFeatureFlag' },
            token
          );
        });
        return {
          name: flag.name,
          featureEnabled: false,
        };
      }
    });

    return processedFlags.reduce(
      (acc, flag) => ({
        ...acc,
        [flag.name]: flag.featureEnabled,
      }),
      {}
    );
  };

  useEffect(() => {
    dispatch(FeatureFlagsActions.flagsLoading(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    if (featureFlagsData && userTypeCodeData) {
      const currentData = JSON.stringify(featureFlagsData);

      // If the data has changed, show a toast
      if (previousDataRef.current && previousDataRef.current !== currentData) {
        addToast({
          type: ToastType.GENERIC,
          content: intl.formatMessage({
            defaultMessage:
              'To ensure you have the latest version of the app and access to all features, please refresh your screen.',
          }),
        });
      }

      previousDataRef.current = currentData;

      const processedFlags = processTheFlags(featureFlagsData);
      dispatch(FeatureFlagsActions.setFeatureFlags(processedFlags));
    }
  }, [
    featureFlagsData,
    userTypeCodeData,
    permissions,
    currentPractice,
    acbKey,
    dispatch,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 600000);

    return () => clearInterval(intervalId);
  }, [refetch]);
};

export default useGetDynamicFeatureFlags;
