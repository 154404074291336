import React from 'react';
import { PageLoader } from '@covetrus/design-system-library';
import LoaderStyled from './styles/LoaderStyled';

const FullScreenLoader: React.FC = () => {
    return <LoaderStyled>
      <PageLoader />
    </LoaderStyled>;
}

export default FullScreenLoader;