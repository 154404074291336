import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Radio, RadioTypesSize } from '@covetrus/design-system-library';
import { useNavigate } from 'react-router';
import usePracticeSelection, {
  type UserContext,
} from '../../hooks/usePracticeSelection/usePracticeSelection';
import { ContextCode } from '../../hooks/usePracticeSelection/types';
import {
  ProfileItemsStyled,
  ProfileSelectionStyled,
  ProfileSubHeader,
} from './styles/ProfileSelectionStyled';

export default function PracticeSelectionRadioGroup() {
  const {
    contextOptions,
    currentContext,
    selectContext,
    selectPractice,
    getPracticeLocations,
  } = usePracticeSelection();
  const navigate = useNavigate();
  const intl = useIntl();

  const { corporateContexts, practiceContexts } = useMemo(() => {
    const corporateCtxs: Array<UserContext> = [];
    const practiceCtxs: Array<UserContext> = [];

    for (const context of contextOptions) {
      switch (context.userContextCode) {
        case ContextCode.Corporate:
          corporateCtxs.push(context);
          break;
        case ContextCode.Practice:
          practiceCtxs.push(context);
          break;
        default:
          break;
      }
    }

    return {
      corporateContexts: corporateCtxs,
      practiceContexts: practiceCtxs,
    };
  }, [contextOptions]);

  function handleSelection(contextKey: string) {
    const updatedContext = selectContext(contextKey);
    if (updatedContext.userContextCode === ContextCode.Corporate) {
      getPracticeLocations(updatedContext);
    } else {
      selectPractice(updatedContext.animalCareBusinessKey);
    }
    navigate('/');
  }

  return (
    <ProfileSelectionStyled>
      <b>{intl.formatMessage({ defaultMessage: 'Profiles:' })}</b>
      {!!corporateContexts.length && (
        <>
          <ProfileSubHeader>
            {intl.formatMessage({ defaultMessage: 'Corporate Groups' })}
          </ProfileSubHeader>
          <ProfileItemsStyled>
            {corporateContexts
              .sort((ctxA, ctxB) =>
                ctxA.contextDescription.localeCompare(ctxB.contextDescription)
              )
              .map((context) => {
                const id = `radio-context-${context.contextKey}`;
                return (
                  <Radio
                    key={id}
                    id={id}
                    checked={context.contextKey === currentContext.contextKey}
                    value={context.contextKey}
                    label={context.contextDescription}
                    radioSize={RadioTypesSize.LG}
                    onChange={() => {
                      handleSelection(context.contextKey);
                    }}
                  />
                );
              })}
          </ProfileItemsStyled>
        </>
      )}
      {!!practiceContexts.length && (
        <>
          <ProfileSubHeader>
            {intl.formatMessage({ defaultMessage: 'Practices' })}
          </ProfileSubHeader>
          <ProfileItemsStyled>
            {practiceContexts
              .sort((ctxA, ctxB) =>
                ctxA.contextDescription.localeCompare(ctxB.contextDescription)
              )
              .map((context) => {
                const id = `radio-context-${context.contextKey}`;
                return (
                  <Radio
                    key={id}
                    id={id}
                    checked={context.contextKey === currentContext.contextKey}
                    value={context.contextKey}
                    label={context.contextDescription}
                    radioSize={RadioTypesSize.LG}
                    onChange={() => {
                      handleSelection(context.contextKey);
                    }}
                  />
                );
              })}
          </ProfileItemsStyled>
        </>
      )}
    </ProfileSelectionStyled>
  );
}
